import React, { Fragment, useMemo, useRef, useState } from 'react'
import {
  Eye as EyeIcon,
  File as FileIcon,
  PlusCircle as PlusCircleIcon,
  Trash2 as Trash2Icon,
  UploadCloud as UploadCloudIcon,
} from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import { isEmpty, map } from 'lodash'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Tooltip,
} from '@material-ui/core'

import { AlertDialog } from 'components'
import { DetailsPlanForm, SummaryFormItems } from './components'

import helpers from 'helpers'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const EvidenceQuestion = ({
  field,
  users,
  componentIndex,
  governanceProject,
  governanceProjectId,
  disabledAll,
  canEditActivity,
  states,
  setStates,
}) => {
  const { CONCLUSION_STATUS_ID, HEADWAY_STATUS_ID } = constants.planAction

  const { updateFileList, handleRemoveFile } = helpers.validates.file

  const { planActionsOpened } = states
  const {
    setLoading,
    setPlanActionsOpened,
    setDefaultValues,
    setModifiedForm,
  } = setStates

  const { user } = useAuth()

  const name = `evidencePlanActions[${componentIndex}]`
  const evidence = field?.evidence
  const planActions = field?.planActions
  const evidencePlanActionId = field.id

  const evidenceDisabled = useMemo(() => {
    return map(
      planActions,
      (planAction) => planAction?.responsible?.id,
    ).includes(user?.id)
  }, [planActions, user?.id])

  const [openDialog, setOpenDialog] = useState(false)
  const [openDestroyDocument, setOpenDestroyDocument] = useState(false)
  const [planActionData, setPlanActionData] = useState({})
  const [currentDocumentId, setCurrentDocumentId] = useState(undefined)
  const [expandedAccordions, setExpandedAccordions] = useState([])
  const [existingAttachments, setExistingAttachments] = useState(
    evidence?.attachments ?? [],
  )

  const inputFileRef = useRef(null)
  const snackbar = useSnackbar()
  const classes = useStyles()

  const { control, errors, watch, setValue, triggerValidation, getValues } =
    useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.planActions`,
  })

  const answer = watch('answer')
  const attachments = watch(`${name}.evidence.attachments`)

  const currentAttachments = useMemo(() => {
    return [...(attachments ?? []), ...existingAttachments]
  }, [attachments, existingAttachments])

  const componentError = errors?.evidencePlanActions?.[componentIndex]

  const hasTreatment = answer === 'yes'

  const disabledInputs = (!evidenceDisabled && !canEditActivity) || disabledAll

  const handleInputFile = () => inputFileRef?.current?.click()

  const handleAddPlanAction = () => {
    append({
      responsible: { id: null, name: '' },
      deadlineDate: null,
      what: '',
      how: '',
      because: '',
      where: '',
      howPrice: '',
      status: '',
    })
  }

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => {
    setPlanActionData({})
    setOpenDialog(false)
  }

  const handleAccordionChange = (accordionId) => (event, isExpanded) => {
    setExpandedAccordions((prev) =>
      isExpanded
        ? [...prev, accordionId]
        : prev.filter((id) => id !== accordionId),
    )
  }

  const handleValidationPlanAction = (index, originId) => {
    if (originId) {
      setPlanActionData({ index, originId })
      handleOpenDialog()
    } else {
      remove(index)
    }
  }

  const handleOpenUrl = (url) => window.open(url, '_blank')

  const handleOpenRemoveDocument = (documentId) => {
    setCurrentDocumentId(documentId)
    setOpenDestroyDocument(true)
  }

  const handleRemoveDocument = async () => {
    try {
      setLoading(true)

      await service.dponet.governanceProject.removeEvidenceDocument({
        governanceProjectId,
        evidencePlanActionId,
        documentId: currentDocumentId,
      })

      setExistingAttachments((prev) =>
        prev?.filter((attachment) => attachment?.id !== currentDocumentId),
      )
      snackbar.open({
        message: 'Documento removido com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setOpenDestroyDocument(false)
      setCurrentDocumentId()
      setLoading(false)
    }
  }

  const handleDeletePlanAction = async () => {
    try {
      setLoading(true)

      await service.dponet.governanceProject.deletePlanAction({
        governanceProjectId,
        planActionId: planActionData?.originId,
      })

      snackbar.open({
        message: 'Plano de ação excluído com sucesso!',
        variant: 'success',
      })
      remove(planActionData?.index)
      handleCloseDialog()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleReopenPlanAction = async (event, originId) => {
    event.stopPropagation()

    try {
      setLoading(true)

      await service.dponet.governanceProject.changeStatusPlanAction({
        governanceProjectId,
        planActionId: originId,
        planAction: {
          status: HEADWAY_STATUS_ID,
        },
      })

      snackbar.open({
        message: 'Plano de ação reaberto com sucesso!',
        variant: 'success',
      })
      setModifiedForm(true)
      setPlanActionsOpened((prev) => [...prev, originId])
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleFinalizePlanAction = async (index) => {
    if (!(await triggerValidation(`${name}.planActions[${index}]`))) return

    try {
      setLoading(true)

      const formValues = getValues()
      const structured = helpers.functions.unflattenObject(formValues)

      const evidencePlanAction =
        structured?.evidencePlanActions?.[componentIndex]

      const planAction = evidencePlanAction?.planActions[index]

      const evidencePlanActions = [
        {
          id: evidencePlanActionId,
          planActions: [
            {
              id: planAction?.originId,
              responsibleUserId: planAction?.responsible?.id,
              deadlineDate: moment(planAction?.deadlineDate).toISOString(),
              what: planAction?.what,
              how: planAction?.how,
              because: planAction?.because,
              where: planAction?.where,
              howPrice: planAction?.howPrice,
              status: CONCLUSION_STATUS_ID,
            },
          ],
        },
      ]

      await service.dponet.governanceProject.answerPlanAction({
        governanceProjectId,
        evidencePlanActions,
      })

      snackbar.open({
        message: 'Plano de ação concluído com sucesso!',
        variant: 'success',
      })

      setDefaultValues((prevValues) => {
        const newValues = { ...prevValues }

        if (
          newValues?.evidencePlanActions?.[componentIndex]?.planActions[index]
        ) {
          newValues.evidencePlanActions[componentIndex].planActions[index] = {
            ...newValues.evidencePlanActions[componentIndex].planActions[index],
            responsibleUserId: planAction?.responsible?.id,
            deadlineDate: moment(planAction?.deadlineDate).toISOString(),
            what: planAction?.what,
            how: planAction?.how,
            because: planAction?.because,
            where: planAction?.where,
            howPrice: planAction?.howPrice,
          }
        }

        return newValues
      })

      setPlanActionsOpened((prev) =>
        prev?.filter((id) => id !== planAction?.originId),
      )
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Paper variant="outlined" className={classes.sectionPaper}>
        <Box>
          <Typography variant="h6" color="secondary" gutterBottom>
            {`${componentIndex + 1}. ${evidence?.title}`}
          </Typography>
          <Typography variant="body1" color="secondary" gutterBottom>
            {evidence?.description}
          </Typography>
        </Box>
        <Paper variant="outlined" className={classes.attachmentPaper}>
          <Controller
            as={<Fragment />}
            name={`${name}.originId`}
            control={control}
          />
          <Controller
            as={
              <TextField
                disabled={disabledInputs}
                variant="outlined"
                error={!!componentError?.evidence?.justify}
                helperText={componentError?.evidence?.justify?.message}
                multiline
                minRows={4}
                maxRows={18}
                placeholder="Utilize este espaço para explicar ou comentar a evidência. As informações inseridas aqui serão exibidas posteriormente no relatório."
                fullWidth
              />
            }
            name={`${name}.evidence.justify`}
            control={control}
          />
          {!isEmpty(currentAttachments) && (
            <Paper variant="outlined">
              <PerfectScrollbar
                options={{
                  wheelPropagation: true,
                  useBothWheelAxes: false,
                }}
                className={classes.attachmentItems}
              >
                {Array.from(currentAttachments)?.map((attachment, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      alignContent="center"
                      gridGap={theme.spacing(1)}
                    >
                      <FileIcon size={20} />
                      <Typography>{attachment?.name}</Typography>
                    </Box>
                    <Box display="flex">
                      <Box>
                        <IconButton
                          variant="contained"
                          onClick={() => handleOpenUrl(attachment?.url)}
                        >
                          <EyeIcon size={20} />
                        </IconButton>
                      </Box>
                      {!disabledInputs && (
                        <IconButton
                          disabled={disabledInputs}
                          onClick={(event) =>
                            attachment?.id
                              ? handleOpenRemoveDocument(attachment?.id)
                              : handleRemoveFile({
                                  attachments,
                                  controlName: `${name}.evidence.attachments`,
                                  event,
                                  index,
                                  setValue,
                                  inputRef: inputFileRef,
                                })
                          }
                        >
                          <Trash2Icon size={20} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                ))}
              </PerfectScrollbar>
            </Paper>
          )}
          {!disabledInputs && (
            <Box>
              <Box className={classes.attachmentButtonContainer}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    as={
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<UploadCloudIcon />}
                        className={clsx(classes.attachmentButton, {
                          [classes.errorButton]:
                            !!componentError?.evidence?.attachments,
                        })}
                        onClick={handleInputFile}
                        disabled={disabledInputs}
                        fullWidth
                      >
                        <input
                          type="file"
                          disabled={disabledInputs}
                          ref={inputFileRef}
                          className={classes.inputFile}
                          accept={constants.validations.ALL_FILE_TYPES}
                          multiple
                        />
                        Selecionar Arquivos
                      </Button>
                    }
                    control={control}
                    name={`${name}.evidence.attachments`}
                    onChange={([event]) => updateFileList(event, attachments)}
                  />
                </Box>
                {!hasTreatment && (
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<PlusCircleIcon />}
                    className={classes.attachmentButton}
                    onClick={handleAddPlanAction}
                    disabled={disabledInputs}
                    fullWidth
                  >
                    Adicionar plano de ação
                  </Button>
                )}
              </Box>
              {!!componentError?.evidence?.attachments && (
                <FormHelperText error={!!componentError?.evidence?.attachments}>
                  {<>{componentError?.evidence?.attachments?.message}</>}
                </FormHelperText>
              )}
            </Box>
          )}
        </Paper>
        {/* Card de plano de ação */}
        {!hasTreatment &&
          fields?.map((planAction, index) =>
            !planActionsOpened.includes(planAction?.originId) &&
            !!planAction?.status ? (
              <Accordion
                className={classes.accordion}
                key={planAction?.id}
                onChange={handleAccordionChange(`plan-action-${index}`)}
                expanded={expandedAccordions.includes(`plan-action-${index}`)}
              >
                <AccordionSummary id={`plan-action-header-${index}`}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridGap={theme.spacing(4)}
                    width="100%"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography variant="h4">Plano de ação</Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        gridGap={theme.spacing(1)}
                      >
                        {!disabledInputs && (
                          <Tooltip title="Reabrir plano de ação">
                            <Box>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={disabledInputs}
                                onClick={(event) =>
                                  handleReopenPlanAction(
                                    event,
                                    planAction?.originId,
                                  )
                                }
                              >
                                Reabrir
                              </Button>
                            </Box>
                          </Tooltip>
                        )}
                        <IconButton>
                          <ExpandMoreIcon
                            className={clsx({
                              [classes.iconTransition]: true,
                              [classes.rotateIcon]: expandedAccordions.includes(
                                `plan-action-${index}`,
                              ),
                            })}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Grid container spacing={3}>
                      <SummaryFormItems
                        name={name}
                        index={index}
                        users={users}
                        planAction={planAction}
                        governanceProject={governanceProject}
                        componentIndex={componentIndex}
                        disabled
                      />
                    </Grid>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <DetailsPlanForm
                      name={name}
                      users={users}
                      index={index}
                      componentIndex={componentIndex}
                      disabled
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Paper key={planAction?.id} className={classes.planActionPaper}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  minHeight={60}
                >
                  <Typography variant="h4">Plano de ação</Typography>
                  {!disabledInputs && (
                    <IconButton
                      disabled={disabledInputs}
                      onClick={() =>
                        handleValidationPlanAction(index, planAction?.originId)
                      }
                    >
                      <Trash2Icon size={20} />
                    </IconButton>
                  )}
                </Box>
                <Grid container spacing={3}>
                  <SummaryFormItems
                    name={name}
                    users={users}
                    index={index}
                    planAction={planAction}
                    governanceProject={governanceProject}
                    componentIndex={componentIndex}
                    disabled={disabledInputs}
                  />
                  <DetailsPlanForm
                    name={name}
                    users={users}
                    index={index}
                    componentIndex={componentIndex}
                    disabled={disabledInputs}
                  />
                  {!disabledInputs && !!planAction?.status && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleFinalizePlanAction(index)}
                        >
                          Concluir plano de ação
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            ),
          )}
      </Paper>
      <AlertDialog
        open={openDialog}
        onClose={handleCloseDialog}
        variant="standard"
        severity="warning"
        content="Tem certeza de que deseja excluir este plano de ação? Essa ação não poderá ser desfeita!"
        dialogActions={
          <>
            <Button
              variant="outlined"
              color="primary"
              className={classes.outlinedWarningButton}
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.containerWarningButton}
              onClick={handleDeletePlanAction}
            >
              Excluir
            </Button>
          </>
        }
      />
      <AlertDialog
        open={openDestroyDocument}
        onClose={() => setOpenDestroyDocument(false)}
        variant="standard"
        severity="warning"
        content="Tem certeza de que deseja excluir este documento? Essa ação não poderá ser desfeita!"
        dialogActions={
          <>
            <Button
              variant="outlined"
              color="primary"
              className={classes.outlinedWarningButton}
              onClick={() => setOpenDestroyDocument(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.containerWarningButton}
              onClick={handleRemoveDocument}
            >
              Excluir
            </Button>
          </>
        }
      />
    </Fragment>
  )
}

EvidenceQuestion.propTypes = {
  field: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  componentIndex: PropTypes.number.isRequired,
  governanceProject: PropTypes.object,
  governanceProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  disabledAll: PropTypes.bool.isRequired,
  canEditActivity: PropTypes.bool.isRequired,
  states: PropTypes.shape({
    planActionsOpened: PropTypes.array.isRequired,
  }),
  setStates: PropTypes.shape({
    setLoading: PropTypes.func.isRequired,
    setPlanActionsOpened: PropTypes.func.isRequired,
    setDefaultValues: PropTypes.func.isRequired,
    setModifiedForm: PropTypes.func.isRequired,
  }),
}

export default EvidenceQuestion
