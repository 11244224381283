import { makeStyles } from '@material-ui/core'

const styles = (isTable, hasPagination, roundedCard) =>
  makeStyles((theme) => ({
    titleContainer: {
      borderBottom: 0,
      borderRadius: roundedCard ? undefined : 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    childrenContainer: {
      borderRadius: 0,
      ...(isTable && !hasPagination && { borderBottom: 0 }),
    },
  }))

export default styles
