import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ governanceProjectId = '', ...params }) => {
  return await dponetAPI.get(`/governance_projects/${governanceProjectId}`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/governance_projects', {
    governanceProject: data,
  })
}

const update = async ({ governanceProjectId, ...data }) => {
  return await dponetAPI.patch(`/governance_projects/${governanceProjectId}`, {
    governanceProject: data,
  })
}

const changeStatus = async ({ governanceProjectId, ...data }) => {
  return await dponetAPI.patch(
    `/governance_projects/${governanceProjectId}/change_status`,
    {
      governanceProject: data,
    },
  )
}

const deletePlanAction = async ({ governanceProjectId, planActionId }) => {
  return await dponetAPI.delete(
    `/governance_projects/${governanceProjectId}/plan_actions/${planActionId}`,
  )
}

const changeStatusPlanAction = async ({
  governanceProjectId,
  planActionId,
  planAction,
}) => {
  return await dponetAPI.patch(
    `/governance_projects/${governanceProjectId}/plan_actions/${planActionId}/change_status`,
    { planAction },
  )
}

const answerPlanAction = async ({
  governanceProjectId,
  evidencePlanActions,
}) => {
  return await dponetAPI.postForm(
    `governance_projects/${governanceProjectId}/evidence_plan_actions/answer`,
    {
      evidencePlanActions,
    },
  )
}

const removeEvidenceDocument = async ({
  governanceProjectId,
  evidencePlanActionId,
  documentId,
}) => {
  return await dponetAPI.delete(
    `governance_projects/${governanceProjectId}/evidence_plan_actions/${evidencePlanActionId}/document/${documentId}`,
  )
}

export default {
  get,
  create,
  update,
  changeStatus,
  deletePlanAction,
  changeStatusPlanAction,
  answerPlanAction,
  removeEvidenceDocument,
}
