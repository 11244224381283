import * as yup from 'yup'
import { isEmpty } from 'lodash'

import helpers from 'helpers'

import constants from 'constants/index'

const { ALL_FILE_TYPES, ALL_FILE_MIMES_TYPES } = constants.validations

const schema = yup.object().shape({
  content: yup.string().when('attachments', {
    is: (attachments) => !isEmpty(attachments),
    then: (schemaItem) =>
      schemaItem
        .required()
        .test(
          'hasContent',
          'Preencha esse campo',
          helpers.validates.jodit.hasContent,
        ),
  }),
  complaintStatus: yup.string().required(),
  attachments: yup
    .mixed()
    .test('fileSize', 'O arquivo deve ter no máximo 10 MB', (value) =>
      helpers.validates.file.size('MB', 10, value),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        ALL_FILE_TYPES,
      )} são permitidos`,
      (value) => helpers.validates.file.type(ALL_FILE_MIMES_TYPES, value),
    ),
})

export default schema
