import permissions from '../permissions'

const GOVERNANCE_PROJECT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.GOVERNANCE_PROJECTS.CREATE,
  },
]

export default GOVERNANCE_PROJECT_LIST
