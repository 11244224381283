import React, { useEffect, useRef, useState } from 'react'
import { size } from 'lodash'
import { Alert } from '@material-ui/lab'
import { Box, Button } from '@material-ui/core'

import {
  AlertDialog,
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
} from 'components'

import { ActivityManageForm } from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import * as service from 'service'
import constants from 'constants/index'

const ActivityManage = ({ match }) => {
  const { governanceProjectId, activityId } = match.params
  const { INITIAL_STATUS_ID, HEADWAY_STATUS_ID } = constants.planAction
  const {
    COMPLETED_STATUS_ID,
    OVERDUE_STATUS_ID,
    IN_PROGRESS_STATUS_ID,
    INACTIVE_STATUS_ID,
  } = constants.governanceProject

  const [loading, setLoading] = useState(false)
  const [planActionsOpened, setPlanActionsOpened] = useState([])
  const [defaultValues, setDefaultValues] = useState({})
  const [modifiedForm, setModifiedForm] = useState(false)
  const [openAlertDialog, setOpenAlertDialog] = useState(false)

  const alertDialogRef = useRef()

  const snackbar = useSnackbar()
  const {
    response: usersResponse,
    isLoading: usersIsLoading,
    refresh: usersRefresh,
  } = useFetch(
    service.dponet.users.get,
    {
      perPage: 10000,
      minimal: true,
      active: true,
      userCompanyStatus: constants.userCompanies.ACTIVE,
    },
    [],
  )

  const users = usersResponse?.data?.users

  const {
    response: activityResponse,
    isLoading: activityIsLoading,
    refresh: activityRefresh,
  } = useFetch(
    service.dponet.governanceProjectActivity.get,
    {
      governanceProjectId,
      id: activityId,
    },
    [],
  )

  const isLoadingResponse = usersIsLoading || activityIsLoading

  const activity = activityResponse?.data?.governanceProjectActivity
  const question = activity?.governanceProjectQuestion

  const isOverdueDate = activity?.deadlineDate > new Date()

  const isCompleted = activity?.status === COMPLETED_STATUS_ID
  const isInProgress = activity?.status === IN_PROGRESS_STATUS_ID
  const isOverdue = activity?.status === OVERDUE_STATUS_ID

  const isInactiveProject =
    activity?.governanceProject?.status === INACTIVE_STATUS_ID
  const isCompletedProject =
    activity?.governanceProject?.status === COMPLETED_STATUS_ID

  const disabledAll = isInactiveProject || isCompletedProject

  const handleRefresh = () => {
    setPlanActionsOpened([])
    setModifiedForm(false)
    usersRefresh()
    activityRefresh()
  }

  const handleOpenAlertDialog = () => {
    const hasPlanActions = activity?.evidencePlanActions?.some(
      (evidence) =>
        Array.isArray(evidence?.planActions) && size(evidence?.planActions) > 0,
    )

    if (!alertDialogRef.current && hasPlanActions) {
      setOpenAlertDialog(true)
      alertDialogRef.current = true
    }
  }
  const handleCloseAlertDialog = () => setOpenAlertDialog(false)

  const handleReOpenActivity = async () => {
    try {
      setLoading(true)

      await service.dponet.governanceProjectActivity.changeStatus({
        governanceProjectId,
        id: activity?.id,
        status: IN_PROGRESS_STATUS_ID,
      })

      snackbar.open({
        message: 'Atividade reaberta com sucesso!',
        variant: 'success',
      })
      handleRefresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPlanActionsOpened((prevState) => {
      const newPlanActionIds =
        activity?.evidencePlanActions?.flatMap((action) =>
          action?.planActions
            ?.filter(
              (planAction) =>
                (planAction?.status === INITIAL_STATUS_ID ||
                  planAction?.status === HEADWAY_STATUS_ID) &&
                !prevState.includes(planAction?.id),
            )
            .map((planAction) => planAction.id)
            .filter(Boolean),
        ) || []

      return [...prevState, ...newPlanActionIds]
    })

    const values = {
      responsible: activity?.assignment || {},
      deadlineDate: activity?.deadlineDate || null,
      answer: activity?.governanceProjectQuestion?.answer || '',
      evidencePlanActions:
        activity?.evidencePlanActions?.map((action) => ({
          ...action,
          originId: action?.id,
          evidence: {
            ...action.evidence,
            attachments: null,
          },
          planActions:
            action?.planActions?.map((plan) => ({
              id: plan?.id || '',
              originId: plan?.id || '',
              how: plan?.how || '',
              what: plan?.what || '',
              where: plan?.where || '',
              howPrice: plan?.howPrice || '',
              because: plan?.because || '',
              deadlineDate: plan?.deadlineDate || '',
              status: plan?.status || '',
              responsible: plan?.responsible || {},
            })) || [],
        })) || [],
    }

    setDefaultValues(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity])

  return (
    <Page title={question?.theme || 'Medida de Segurança'}>
      <Container maxWidth={false}>
        <ContentHeader
          title={question?.theme || 'Medida de Segurança'}
          {...(isCompleted &&
            !modifiedForm &&
            !disabledAll && {
              children: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReOpenActivity}
                >
                  Reabrir Atividade
                </Button>
              ),
            })}
          goBack
        />
        <LoadingFeedback open={loading || isLoadingResponse} />
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
          {(isInProgress || (modifiedForm && !isOverdueDate)) && (
            <Alert severity="info" variant="standard">
              Esta atividade encontra-se pendente. Por favor, conclua todas as
              etapas necessárias antes do prazo e finalize-a utilizando o botão
              disponível no final da página.
            </Alert>
          )}
          {isCompleted && !modifiedForm && (
            <Alert severity="success" variant="standard">
              Esta atividade foi concluída. Para realizar alterações,
              certifique-se de fazê-las antes do encerramento do projeto ou do
              prazo limite.
            </Alert>
          )}
          {(isOverdue || (modifiedForm && isOverdueDate)) && (
            <Alert severity="error" variant="standard">
              Esta atividade está atrasada. Por favor, conclua todas as etapas
              necessárias o mais rápido possível e finalize-a utilizando o botão
              disponível no final da página.
            </Alert>
          )}
          {!isLoadingResponse && (
            <ActivityManageForm
              users={users}
              activity={activity}
              governanceProjectId={governanceProjectId}
              setStates={{
                setLoading,
                setPlanActionsOpened,
                setDefaultValues,
                setModifiedForm,
                handleOpenAlertDialog,
              }}
              states={{
                planActionsOpened,
                defaultValues,
              }}
              refresh={handleRefresh}
            />
          )}
        </Box>
        <AlertDialog
          open={openAlertDialog}
          onClose={handleCloseAlertDialog}
          variant="standard"
          severity="info"
          title="Alteração do prazo da atividade"
          content="Alterar o prazo da atividade pode impactar os prazos dos planos de ação finalizados. Se houver planos de ação com um prazo menor, eles serão ajustados para coincidir com o novo prazo da atividade."
        />
      </Container>
    </Page>
  )
}

export default ActivityManage
