function unflattenObject(flatObj) {
  const result = {}

  for (const key in flatObj) {
    if (Object.prototype.hasOwnProperty.call(flatObj, key)) {
      const keys = key.replace(/\]/g, '').split(/\.|\[/)
      let current = result

      for (let i = 0; i < keys.length; i++) {
        const k = keys[i]
        if (i === keys.length - 1) {
          current[k] = flatObj[key]
        } else {
          if (!current[k]) {
            const nextKey = keys[i + 1]
            current[k] = /^\d+$/.test(nextKey) ? [] : {}
          }
          current = current[k]
        }
      }
    }
  }

  return result
}

export default unflattenObject
