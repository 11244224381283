import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeft as ArrowLeftIcon } from 'react-feather'
import { Box, IconButton, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import HistoryBreadcumbs from './components/HistoryBreadcumbs/HistoryBreadcumbs'

import useStyles from './styles'
import theme from 'theme'

const ContentHeader = ({
  title,
  lightColor = false,
  children,
  smFull = false,
  xsFull = true,
  breakLine = true,
  goBack = false,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()

  const handleGoBack = () => history.goBack()

  return (
    <Box
      pt={title && 3}
      mb={title && 4}
      className={clsx(classes.root, {
        [classes.rootBreakpoints]: breakLine,
      })}
    >
      <Box
        className={clsx(classes.titleContainer, {
          [classes.titleContainerBreakpoints]: breakLine,
        })}
      >
        <HistoryBreadcumbs lightColor={lightColor} />
        <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
          {goBack && (
            <IconButton onClick={handleGoBack}>
              <ArrowLeftIcon
                className={clsx(classes.icon, {
                  [classes.textLighten]: lightColor,
                })}
              />
            </IconButton>
          )}
          <Typography
            className={clsx(classes.h3, { [classes.textLighten]: lightColor })}
            variant="h3"
            color="textPrimary"
          >
            {title}
          </Typography>
        </Box>
      </Box>
      {!!children && (
        <Box
          className={clsx(classes.childrenContainer, {
            [classes.childrenContainerBreakpoints]: breakLine,
          })}
          {...rest}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.string,
  lightColor: PropTypes.bool,
  children: PropTypes.node,
  smFull: PropTypes.bool,
  xsFull: PropTypes.bool,
  goBack: PropTypes.bool,
}

export default ContentHeader
