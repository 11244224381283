import React from 'react'
import { Plus as PlusIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Person as PersonIcon } from '@material-ui/icons'
import { PermittedByAssignmentRole } from 'components'

import constants from 'constants/index'

import useStyles from './styles'

const PeopleCard = ({
  governanceProject,
  handleOpenModal,
  assignments,
  assignmentRole,
}) => {
  const { TRANSLATED_PROJECT_ROLES } = constants.governanceProject

  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Box className={classes.headContainer}>
        <Typography variant="h5">Responsáveis neste projeto</Typography>
        <PermittedByAssignmentRole
          userRole={assignmentRole}
          permittedRoles={['manager', 'project_responsible']}
        >
          <Box
            className={classes.addButton}
            onClick={(event) => {
              event.stopPropagation()
              handleOpenModal(governanceProject)
            }}
          >
            <Typography variant="body2" className={classes.weightTypography}>
              Adicionar mais
            </Typography>
            <PlusIcon size={18} className={classes.weightTypography} />
          </Box>
        </PermittedByAssignmentRole>
      </Box>
      <Box height={150}>
        <PerfectScrollbar
          options={{ wheelPropagation: false, useBothWheelAxes: true }}
          className={classes.scrollbar}
        >
          {assignments?.map((responsible) => (
            <Box key={responsible?.id} className={classes.itemContainer}>
              <Box className={classes.innerContainer}>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Box className={classes.textContainer}>
                  <Tooltip title={responsible?.user?.name}>
                    <Typography
                      variant="h6"
                      noWrap
                      className={classes.responsibleText}
                    >
                      {responsible?.user?.name}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={responsible?.user?.email}
                    className={classes.responsibleText}
                  >
                    <Typography variant="body1" noWrap>
                      {responsible?.user?.email}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title={TRANSLATED_PROJECT_ROLES[responsible?.role]}>
                  <Chip
                    label={TRANSLATED_PROJECT_ROLES[responsible?.role]}
                    color="primary"
                    className={classes.chip}
                  />
                </Tooltip>
              </Box>
            </Box>
          ))}
        </PerfectScrollbar>
      </Box>
    </Paper>
  )
}

PeopleCard.propTypes = {
  governanceProject: PropTypes.object,
  handleOpenModal: PropTypes.func.isRequired,
  assignments: PropTypes.array,
  assignmentRole: PropTypes.string,
}

export default PeopleCard
