const { uniqBy } = require('lodash')

const orderAndClearAssignments = (assignments = []) => {
  const roleOrder = {
    observer: 1,
    manager: 2,
    project_responsible: 3,
    area_responsible: 4,
    activity_responsible: 5,
  }

  const orderedAssignments = assignments?.sort((a, b) => {
    return roleOrder[a.role] - roleOrder[b.role]
  })

  return uniqBy(orderedAssignments, 'user.id')
}

export default orderAndClearAssignments
