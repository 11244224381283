import moment from 'moment'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().max(200).required(),
  startDate: yup.date().required().typeError('Precisa ser uma data válida'),
  endDate: yup
    .date()
    .required()
    .typeError('Precisa ser uma data válida')
    .test(
      'is-greater',
      'A data final não pode ser anterior à data inicial ou à data de hoje',
      function (value) {
        const { startDate } = this.parent
        const adjustedStartDate = moment(startDate).subtract(1, 'day')
        const adjustedToday = moment().subtract(1, 'day')

        return value > moment.max(adjustedToday, adjustedStartDate)
      },
    ),
  projectResponsible: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .required(),
  observer: yup.array().of(
    yup.object().nullable().shape({
      id: yup.number().required(),
      name: yup.string().required(),
    }),
  ),
  originId: yup.string().required(),
  areas: yup.array().of(
    yup.object().shape({
      area: yup
        .object()
        .nullable()
        .shape({
          id: yup.number().required(),
          name: yup.string().required(),
        })
        .required(),
      responsible: yup
        .object()
        .nullable()
        .shape({
          id: yup.number().required(),
          name: yup.string().required(),
        })
        .required(),
    }),
  ),
  purpose: yup.string().max(2000).required(),
})

export default schema
