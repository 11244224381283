import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ governanceProjectTemplateId = '', ...params }) => {
  return await dponetAPI.get('/governance_project_templates', {
    params,
  })
}

export default {
  get,
}
