import { size } from 'lodash'

const input = (value = '') => {
  value = value.replace(/\D/g, '')

  while (size(value) < 3) value = '0' + value

  const integerPart = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  const decimalPart = value.slice(-2)
  const formattedValue = `${integerPart},${decimalPart}`

  return formattedValue.replace(/^0+(?=\d)/, '')
}

const convert = (value = '') =>
  parseFloat(value.replace(/\./g, '').replace(/,/g, '.'))

export default { input, convert }
