import React, { isValidElement } from 'react'
import PropTypes from 'prop-types'

import { Box, Paper, Typography } from '@material-ui/core'

import styles from './styles'

const SectionCard = ({
  title = '',
  children,
  titleContainerProps,
  isTable = false,
  hasPagination = false,
  id = 'section-card',
  roundedCard = false,
  ...rest
}) => {
  const useStyles = styles(isTable, hasPagination, roundedCard)
  const classes = useStyles()

  const isElement = isValidElement(title)

  return (
    <Box id={id}>
      <Paper
        className={classes.titleContainer}
        component={Box}
        px={3}
        py={2}
        {...titleContainerProps}
      >
        {isElement ? title : <Typography variant="h6">{title}</Typography>}
      </Paper>
      <Paper
        className={classes.childrenContainer}
        component={Box}
        p={isTable ? 0 : 3}
        {...rest}
      >
        {children}
      </Paper>
    </Box>
  )
}

SectionCard.propTypes = {
  children: PropTypes.node.isRequired,
  hasPagination: PropTypes.bool,
  id: PropTypes.string,
  isTable: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  titleContainerProps: PropTypes.object,
  roundedCard: PropTypes.bool,
}

export default SectionCard
