import React, { Fragment, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { Box, Button, colors, Paper, Typography } from '@material-ui/core'

import { ActivityBoard } from './components'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

const ProjectBoard = ({
  questionnaires,
  governanceProjectId,
  requestIsLoading,
}) => {
  const { BOARD_CATEGORIES_BUTTONS, CHOOSE_STATUS_BOARD_COLOR } =
    constants.governanceProject

  const [currentTab, setCurrentTab] = useState('all')

  const classes = useStyles()

  return (
    <Fragment>
      <Box className={classes.gridContainer}>
        {BOARD_CATEGORIES_BUTTONS.map((category, index) => {
          const Icon = category?.icon
          const bgColor =
            CHOOSE_STATUS_BOARD_COLOR[category?.id] || colors.grey[500]
          const isSelected = category?.id === currentTab

          return (
            <Box key={index} className={classes.gridItem}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.categoryButton}
                onClick={() => setCurrentTab(category?.id)}
                {...(isSelected && {
                  style: {
                    borderColor: theme.palette.primary.main,
                  },
                })}
              >
                <Box display="flex" justifyContent="center" width="100%">
                  <Typography variant="button">{category?.name}</Typography>
                </Box>
                {!!Icon && (
                  <Box className={classes.containerIcon} bgcolor={bgColor}>
                    <Icon
                      size={20}
                      strokeWidth={2.5}
                      className={classes.categoryIcon}
                    />
                  </Box>
                )}
              </Button>
            </Box>
          )
        })}
      </Box>
      <Box>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
            useBothWheelAxes: true,
            suppressScrollY: true,
          }}
          className={classes.boardScrollbar}
        >
          <Box display="flex" gridGap={theme.spacing(2)}>
            {requestIsLoading
              ? [...Array(4)].map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rect"
                    animation="wave"
                    width={384}
                    height={599}
                  />
                ))
              : questionnaires?.map((questionnaire) => {
                  const filterActivitiesByStatus =
                    questionnaire?.governanceProjectActivities?.filter(
                      (activity) =>
                        currentTab === 'all' || activity?.status === currentTab,
                    )

                  return (
                    <Box
                      key={questionnaire?.id}
                      height={600}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box className={classes.boardHeader}>
                        <Typography variant="h5">
                          {questionnaire?.kind}
                        </Typography>
                      </Box>
                      <Box className={classes.boardContent}>
                        <PerfectScrollbar
                          options={{
                            wheelPropagation: true,
                            useBothWheelAxes: true,
                          }}
                          className={classes.scrollbar}
                        >
                          <Box className={classes.boardBody}>
                            {isEmpty(filterActivitiesByStatus) ? (
                              <Paper
                                variant="outlined"
                                className={classes.notFoundActivity}
                              >
                                <Typography>
                                  Nenhuma atividade foi encontrada.
                                </Typography>
                              </Paper>
                            ) : (
                              filterActivitiesByStatus?.map((activity) => (
                                <ActivityBoard
                                  key={activity?.id}
                                  governanceProjectId={governanceProjectId}
                                  activity={activity}
                                />
                              ))
                            )}
                          </Box>
                        </PerfectScrollbar>
                      </Box>
                    </Box>
                  )
                })}
          </Box>
        </PerfectScrollbar>
      </Box>
    </Fragment>
  )
}

ProjectBoard.propTypes = {
  questionnaires: PropTypes.arrayOf(PropTypes.object),
  requestIsLoading: PropTypes.bool.isRequired,
}

export default ProjectBoard
