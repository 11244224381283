import api from 'service/api'

const dponetAPI = api.create('dponet')

const resume = async ({ governanceProjectId = '', ...params }) => {
  return await dponetAPI.get(
    `/governance_projects/${governanceProjectId}/governance_project_questionnaires/resume`,
    {
      params,
    },
  )
}

export default {
  resume,
}
