import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  sectionPaper: {
    padding: theme.spacing(3),
  },
  orderedList: {
    marginLeft: theme.spacing(3),
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: theme.typography.body1.fontWeight,
    lineHeight: theme.typography.body1.lineHeight,
    lineSpacing: theme.typography.body1.lineSpacing,
    color: theme.palette.text.secondary,
  },
  codeConductContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  videoPlayer: {
    aspectRatio: '16/9',
    width: 533,
    height: 300,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  radioError: {
    color: theme.palette.error.main,
  },
}))

export default styles
