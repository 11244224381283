import React, { Fragment, useEffect, useState } from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import { User as UserIcon, Watch as WatchIcon } from 'react-feather'
import PropTypes from 'prop-types'
import moment from 'moment'
import clsx from 'clsx'
import { isEmpty, size } from 'lodash'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Autocomplete } from '@material-ui/lab'
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { EvidenceQuestion } from './components'
import { PermittedByAssignmentRole } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'
import useAssignmentPermission from 'hooks/useAssignmentPermission'

import theme from 'theme'
import schema from './schema'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const ActivityManageForm = ({
  users = [],
  activity,
  refresh,
  states,
  setStates,
  governanceProjectId,
}) => {
  const { INITIAL_STATUS_ID, HEADWAY_STATUS_ID } = constants.planAction
  const {
    QUESTION_RADIO_FIELDS_CONTROLLER,
    IN_PROGRESS_STATUS_ID,
    COMPLETED_STATUS_ID,
    INACTIVE_STATUS_ID,
  } = constants.governanceProject

  const { isLoading, defaultValues, planActionsOpened } = states
  const { setLoading, handleOpenAlertDialog } = setStates

  const [madePartialRequest, setMadePartialRequest] = useState(false)
  const [activityDeadlineDate, setActivityDeadlineDate] = useState(
    moment(activity?.deadlineDate),
  )

  const classes = useStyles()
  const snackbar = useSnackbar()

  const minDate = moment(activity?.governanceProject?.startDate)
  const maxDate = moment(activity?.governanceProject?.endDate)
  const {
    assignmentRole,
    isManager,
    isProjectResponsible,
    isAreaResponsible,
    isActivityResponsible,
    isPlanActionResponsible,
  } = useAssignmentPermission({
    governanceProject: activity?.governanceProject,
  })

  const formMethods = useForm({
    validationSchema: schema({
      minDate,
      maxDate,
      planActionsOpened,
      activityDeadlineDate,
    }),
    defaultValues: defaultValues,
  })

  const {
    control,
    errors,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { dirty },
  } = formMethods

  const answer = watch('answer')
  const getDeadlineDate = getValues('deadlineDate')

  const isCompleted = activity?.status === COMPLETED_STATUS_ID

  const isInactiveProject =
    activity?.governanceProject?.status === INACTIVE_STATUS_ID
  const isCompletedProject =
    activity?.governanceProject?.status === COMPLETED_STATUS_ID

  const canEditActivity =
    isManager ||
    isProjectResponsible ||
    isAreaResponsible ||
    isActivityResponsible

  const disabledAll = isCompleted || isInactiveProject || isCompletedProject
  const disabledInputs = disabledAll || !canEditActivity

  const question = activity?.governanceProjectQuestion
  const videoUrl = activity?.governanceProjectQuestion?.videoUrl

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      const activityData = {
        answer: data?.answer,
        deadlineDate: moment(data?.deadlineDate).toISOString(),
        responsibleId: data?.responsible?.id,
        ...(!!data?.answer && { status: IN_PROGRESS_STATUS_ID }),
      }

      const evidencePlanActionsData = data?.evidencePlanActions?.map(
        (action) => ({
          id: action?.originId,
          evidence: {
            justify: action?.evidence?.justify,
            attachments: action?.evidence?.attachments,
          },
          planActions: action?.planActions?.map((plan) => ({
            id: plan?.originId,
            responsibleUserId: plan?.responsible?.id,
            deadlineDate: moment(plan?.deadlineDate).toISOString(),
            what: plan?.what,
            how: plan?.how,
            because: plan?.because,
            where: plan?.where,
            howPrice: plan?.howPrice,
            status: !!plan?.status
              ? planActionsOpened.includes(plan?.originId)
                ? HEADWAY_STATUS_ID
                : plan?.status
              : INITIAL_STATUS_ID,
          })),
        }),
      )

      if (
        size(evidencePlanActionsData) > 0 &&
        (canEditActivity || isPlanActionResponsible)
      ) {
        await service.dponet.governanceProject.answerPlanAction({
          governanceProjectId,
          evidencePlanActions: evidencePlanActionsData,
        })
      }

      setMadePartialRequest(true)

      if (canEditActivity) {
        await service.dponet.governanceProjectActivity.answer({
          governanceProjectId,
          id: activity?.id,
          ...activityData,
        })
      }

      snackbar.open({
        message: 'Atividade atualizada com sucesso!',
        variant: 'success',
      })
      reset()
      refresh()
      setMadePartialRequest(false)
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCompletedActivity = async () => {
    try {
      setLoading(true)

      await service.dponet.governanceProjectActivity.changeStatus({
        governanceProjectId,
        id: activity?.id,
        status: COMPLETED_STATUS_ID,
      })

      snackbar.open({
        message: 'Atividade finalizada com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setActivityDeadlineDate(getDeadlineDate)
  }, [getDeadlineDate])
  useEffect(() => {
    if (madePartialRequest && !isLoading) refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [madePartialRequest, isLoading])

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(3)}
          textAlign="justify"
        >
          <Paper variant="outlined" className={classes.sectionPaper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  Responsável pela atividade
                </Typography>
                <Controller
                  as={
                    <Autocomplete
                      options={users}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) =>
                        option.id === value.id || value.id === 0
                      }
                      disabled={disabledInputs}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={disabledInputs}
                          error={!!errors.responsible}
                          helperText={
                            errors?.responsible?.message ||
                            errors?.responsible?.id?.message
                          }
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <UserIcon
                                  color={theme.palette.secondary.main}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  }
                  control={control}
                  name="responsible"
                  mode="onChange"
                  onChange={([, data]) => data}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  Prazo da atividade
                </Typography>
                <Controller
                  as={
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      format="DD/MM/yyyy"
                      error={!!errors.deadlineDate}
                      helperText={errors?.deadlineDate?.message}
                      onChange={(newValue) => ({ value: newValue })}
                      disablePast
                      minDate={minDate}
                      maxDate={maxDate}
                      disabled={disabledInputs}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WatchIcon color={theme.palette.secondary.main} />
                          </InputAdornment>
                        ),
                      }}
                      onClick={handleOpenAlertDialog}
                    />
                  }
                  control={control}
                  name="deadlineDate"
                  mode="onChange"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" className={classes.sectionPaper}>
            <Box
              display="flex"
              flexDirection="column"
              gridGap={theme.spacing(2)}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  xl={!!videoUrl ? 6 : 12}
                  className={classes.codeConductContainer}
                >
                  <Box>
                    <Typography variant="h4" gutterBottom>
                      {question?.theme}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {question?.question}
                    </Typography>
                    <Typography variant="body1" color="secondary" gutterBottom>
                      {question?.explanation}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" color="secondary" gutterBottom>
                      Evidências exigidas:
                    </Typography>
                    <ol className={classes.orderedList}>
                      {activity?.evidencePlanActions?.map((item) => (
                        <li key={item?.evidence?.id}>
                          {item?.evidence?.title};
                        </li>
                      ))}
                    </ol>
                  </Box>
                  <Box>
                    <Controller
                      as={
                        <RadioGroup
                          row
                          className={clsx({
                            [classes.radioError]: !!errors.answer,
                          })}
                        >
                          {QUESTION_RADIO_FIELDS_CONTROLLER.map(
                            (field, index) => (
                              <FormControlLabel
                                disabled={disabledInputs}
                                key={index}
                                value={field?.value}
                                control={
                                  <Radio
                                    className={clsx({
                                      [classes.radioError]: !!errors.answer,
                                    })}
                                  />
                                }
                                label={field?.label}
                              />
                            ),
                          )}
                        </RadioGroup>
                      }
                      control={control}
                      name="answer"
                    />
                    {!!errors?.answer && (
                      <FormHelperText error={!!errors.answer}>
                        {<>{errors?.answer?.message}</>}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                {!!videoUrl && (
                  <Grid item xs={12} xl={6}>
                    <Box display="flex" justifyContent="center">
                      <iframe
                        className={classes.videoPlayer}
                        src={videoUrl}
                        title={activity?.governanceProjectQuestion.theme}
                        allow="fullscreen; picture-in-picture; clipboard-write"
                        frameBorder="0"
                        allowFullScreen
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {!isEmpty(answer) && (
                <Fragment>
                  <Typography variant="h4">Evidências</Typography>
                  {activity?.evidencePlanActions.map((field, index) => (
                    <EvidenceQuestion
                      key={field.id}
                      field={field}
                      users={users}
                      componentIndex={index}
                      governanceProject={activity?.governanceProject}
                      governanceProjectId={governanceProjectId}
                      disabledAll={disabledAll}
                      canEditActivity={canEditActivity}
                      states={{ planActionsOpened }}
                      setStates={setStates}
                    />
                  ))}
                </Fragment>
              )}
            </Box>
          </Paper>
          {!disabledAll && (
            <Box
              display="flex"
              justifyContent="flex-end"
              gridGap={theme.spacing(1)}
            >
              <PermittedByAssignmentRole
                unpermittedRoles={['observer']}
                userRole={assignmentRole}
              >
                <Button variant="contained" color="primary" type="submit">
                  Salvar
                </Button>
              </PermittedByAssignmentRole>
              <PermittedByAssignmentRole
                unpermittedRoles={['plan_action_responsible', 'observer']}
                userRole={assignmentRole}
              >
                <Tooltip
                  title={
                    dirty
                      ? 'Salve as alterações antes de tentar finalizar a atividade!'
                      : ''
                  }
                >
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCompletedActivity}
                      disabled={dirty}
                    >
                      Finalizar atividade
                    </Button>
                  </Box>
                </Tooltip>
              </PermittedByAssignmentRole>
            </Box>
          )}
        </Box>
      </form>
    </FormContext>
  )
}

ActivityManageForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  activity: PropTypes.object,
  governanceProjectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  refresh: PropTypes.func.isRequired,
  states: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    defaultValues: PropTypes.object,
    planActionsOpened: PropTypes.array.isRequired,
  }),
  setStates: PropTypes.shape({
    setLoading: PropTypes.func.isRequired,
    setPlanActionsOpened: PropTypes.func.isRequired,
    setDefaultValues: PropTypes.func.isRequired,
    setModifiedForm: PropTypes.func.isRequired,
    handleOpenAlertDialog: PropTypes.func.isRequired,
  }),
}

export default ActivityManageForm
