import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, Typography } from '@material-ui/core'

import { RadialGradientChart } from 'components'

import useStyles from './styles'
import theme from 'theme'

const RadialGraphicsCard = ({
  title,
  subtitle,
  value = 0,
  percentage = 0,
  onlyNumber = false,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Box className={classes.comumContainer}>
        <Box mt={1}>
          <RadialGradientChart
            value={percentage}
            trackBackground={theme.palette.dashboard.chart.secondary.pending}
            {...(onlyNumber && { valueFormat: () => `${value}` })}
            hollowSize="30%"
            fontSize="8px"
            offsetY={2}
            height={80}
            width={60}
            scale={2}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="justify"
        >
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2">{subtitle}</Typography>
        </Box>
      </Box>
    </Paper>
  )
}

RadialGraphicsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  value: PropTypes.number,
  onlyNumber: PropTypes.bool,
  percentage: PropTypes.number,
}

export default RadialGraphicsCard
