const INITIAL_STATUS_ID = 'initial'
const HEADWAY_STATUS_ID = 'headway'
const CONCLUSION_STATUS_ID = 'conclusion'
const INACTIVE_STATUS_ID = 'inactive'

const EVIDENCE_FILE_TYPES = '.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx'
const EVIDENCE_FILE_MIMES_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const PLAN_ACTION_FIELDS_CONTROLLER = [
  {
    name: 'what',
    label: 'O que?',
    placeholder: 'Ações concretas e objetivas que serão tomadas',
    rows: 4,
    md: 12,
  },
  {
    name: 'how',
    label: 'Como?',
    placeholder: 'Planejamento para execução da ação',
    rows: 4,
    md: 6,
  },
  {
    name: 'because',
    label: 'Por quê?',
    placeholder: 'A meta que se objetiva com a ação',
    rows: 4,
    md: 6,
  },
  {
    name: 'where',
    label: 'Onde?',
    placeholder: 'Em qual setor a ação será realizada',
    md: 6,
  },
  {
    name: 'howPrice',
    label: 'Quanto?',
    placeholder: 'Impacto financeiro da ação (R$)',
    startIcon: 'R$',
    inputMode: 'decimal',
    md: 6,
  },
]

export default {
  PLAN_ACTION_FIELDS_CONTROLLER,
  CONCLUSION_STATUS_ID,
  HEADWAY_STATUS_ID,
  INACTIVE_STATUS_ID,
  INITIAL_STATUS_ID,
  EVIDENCE_FILE_TYPES,
  EVIDENCE_FILE_MIMES_TYPES,
}
