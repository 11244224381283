import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon, Plus as PlusIcon } from 'react-feather'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Button,
  Grid,
  TablePagination,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'

import {
  AlertDialog,
  Container,
  ContentHeader,
  LoadingFeedback,
  NoData2,
  Page,
  Permitted,
} from 'components'
import { ProjectSettingsModal, SummaryCard } from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import usePagination from 'hooks/usePagination'

import theme from 'theme'
import { routes } from 'Routes'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const GovernanceProjectsMain = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openFinalizedModal, setOpenFinalizedModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [projectData, setProjectData] = useState()

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(4)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const {
    response: projectResponse,
    isLoading: projectIsLoading,
    refresh: projectRefresh,
  } = useFetch(
    service.dponet.governanceProject.get,
    {
      page,
      perPage,
    },
    [page, perPage],
  )

  const governanceProjects = projectResponse?.data?.governanceProjects

  const { response: templateResponse, isLoading: templateIsLoading } = useFetch(
    service.dponet.governanceProjectTemplate.get,
    {},
    [],
  )

  const projectTemplates = templateResponse?.data?.governanceProjectTemplates

  const { response: usersResponse, isLoading: usersIsLoading } = useFetch(
    service.dponet.users.get,
    {
      perPage: 10000,
      minimal: true,
      active: true,
      userCompanyStatus: constants.userCompanies.ACTIVE,
    },
  )

  const users = usersResponse?.data?.users

  const isLoadingRequests =
    projectIsLoading || templateIsLoading || usersIsLoading

  const handleProjectManagerRedirection = (governanceProjectId) => {
    history.push(
      reverse(routes.governanceProjects.manage, { governanceProjectId }),
    )
  }

  const handleOpenModal = (governanceProjectData) => {
    setProjectData(governanceProjectData)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setProjectData()
    setOpenModal(false)
  }

  const handleOpenFinalizedModal = (governanceProjectData) => {
    setProjectData(governanceProjectData)
    setOpenFinalizedModal(true)
  }

  const handleCloseFinalizedModal = () => {
    setProjectData()
    setOpenFinalizedModal(false)
  }

  const handleFinalizedProject = async () => {
    try {
      setLoading(true)

      await service.dponet.governanceProject.changeStatus({
        governanceProjectId: projectData.id,
        status: constants.governanceProject.COMPLETED_STATUS_ID,
      })

      snackbar.open({
        message: 'Projeto finalizado com sucesso!',
        variant: 'success',
      })
      projectRefresh()
      handleCloseFinalizedModal()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page title="Projetos de Governança">
      <Container maxWidth={false}>
        <LoadingFeedback open={loading} />
        <ContentHeader title="Projetos de Governança">
          {/* <Box>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                id={MAIN_STEP_1}
                fullWidth
              >
                Tutorial
              </Button>
            </Tooltip>
          </Box> */}
          <Permitted tag={constants.permissions.GOVERNANCE_PROJECTS.CREATE}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<PlusIcon />}
              onClick={() => handleOpenModal()}
              disabled={isLoadingRequests}
            >
              Criar projeto
            </Button>
          </Permitted>
        </ContentHeader>
        {isLoadingRequests ? (
          <Grid container spacing={2}>
            {[...Array(2)].map((_, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Skeleton
                  key={index}
                  variant="rect"
                  animation="wave"
                  height={759}
                />
              </Grid>
            ))}
          </Grid>
        ) : isEmpty(governanceProjects) ? (
          <NoData2 title="Nenhum projeto de governança foi encontrado." />
        ) : (
          <Fragment>
            <Grid container spacing={2}>
              {governanceProjects?.map((governanceProject) => (
                <Grid key={governanceProject.id} item xs={12} md={6}>
                  <SummaryCard
                    governanceProject={governanceProject}
                    handleProjectManagerRedirection={
                      handleProjectManagerRedirection
                    }
                    handleOpenModal={handleOpenModal}
                    handleOpenFinalizedModal={handleOpenFinalizedModal}
                  />
                </Grid>
              ))}
            </Grid>
            <TablePagination
              component="div"
              count={projectResponse?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[4, 8, 16, 20]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
        {openModal && (
          <ProjectSettingsModal
            open={openModal}
            setOpen={handleCloseModal}
            setLoading={setLoading}
            isEdit={!!projectData?.id}
            users={users}
            governanceProject={projectData}
            projectTemplates={projectTemplates}
            refresh={projectRefresh}
          />
        )}
        {openFinalizedModal && (
          <AlertDialog
            open={openFinalizedModal}
            onClose={handleCloseFinalizedModal}
            variant="standard"
            severity="success"
            title="Finalizar projeto de governança"
            content="Tem certeza de que deseja finalizar este projeto de governança? Após a finalização, não será possível reabri-lo!"
            dialogActions={
              <Fragment>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.outlinedSuccessButton}
                  onClick={handleCloseFinalizedModal}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.containerSuccessButton}
                  onClick={handleFinalizedProject}
                >
                  Finalizar
                </Button>
              </Fragment>
            }
          />
        )}
      </Container>
    </Page>
  )
}

export default GovernanceProjectsMain
