import { makeStyles } from '@material-ui/core'
import helpers from 'helpers'

const styles = makeStyles((theme) => ({
  sectionPaper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(3),
  },
  attachmentPaper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
  },
  planActionPaper: {
    paddingInline: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
  },
  attachmentItems: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    maxHeight: 215,
  },
  attachmentButtonContainer: {
    display: 'flex',
    gridGap: theme.spacing(3),
    flexDirection: 'row',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gridGap: theme.spacing(2),
    },
  },
  attachmentButton: {
    padding: theme.spacing(2),
  },
  inputFile: {
    display: 'none',
  },
  accordion: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderColor: theme.palette.success.main,
    boxShadow: `0px 0px 5px ${helpers.palette.hexToRgba(
      theme.palette.success.main,
      2.0,
    )}`,
    '&::before': {
      backgroundColor: theme.palette.success.main,
    },
  },
  outlinedWarningButton: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '&:hover': {
      borderColor: theme.palette.warning.dark,
    },
  },
  containerWarningButton: {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
  iconTransition: {
    transition: 'transform 0.3s',
  },
}))

export default styles
