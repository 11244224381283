import {
  AlertTriangle as AlertTriangleIcon,
  Check as CheckIcon,
  Clock as ClockIcon,
  Eye as EyeIcon,
  MoreHorizontal as MoreHorizontalIcon,
  User as UserIcon,
} from 'react-feather'
import { colors } from '@material-ui/core'
import theme from 'theme'

const PENDING_STATUS_ID = 'pending'
const OVERDUE_STATUS_ID = 'overdue'
const COMPLETED_STATUS_ID = 'completed'
const INACTIVE_STATUS_ID = 'inactive'

const IN_PROGRESS_STATUS_ID = 'ongoing'

const OBSERVER_ROLE = 'observer'
const MANAGER_ROLE = 'manager'
const PROJECT_RESPONSIBLE = 'project_responsible'
const AREA_RESPONSIBLE = 'area_responsible'
const ACTIVITY_RESPONSIBLE = 'activity_responsible'
const PLAN_ACTION_RESPONSIBLE = 'plan_action_responsible'

const BOARD_CATEGORIES_BUTTONS = [
  {
    id: 'all',
    name: 'Todas',
  },
  {
    id: PENDING_STATUS_ID,
    name: 'Pendentes',
    icon: MoreHorizontalIcon,
  },
  {
    id: IN_PROGRESS_STATUS_ID,
    name: 'Em andamento',
    icon: ClockIcon,
  },
  {
    id: OVERDUE_STATUS_ID,
    name: 'Atrasadas',
    icon: AlertTriangleIcon,
  },
  {
    id: COMPLETED_STATUS_ID,
    name: 'Concluídas',
    icon: CheckIcon,
  },
]

const CHOOSE_STATUS_BOARD_COLOR = {
  [PENDING_STATUS_ID]: colors.blueGrey[500],
  [IN_PROGRESS_STATUS_ID]: colors.blue[500],
  [OVERDUE_STATUS_ID]: colors.red[500],
  [COMPLETED_STATUS_ID]: colors.green[500],
}

const CHOOSE_STATUS_BOARD_ICON = {
  [PENDING_STATUS_ID]: MoreHorizontalIcon,
  [IN_PROGRESS_STATUS_ID]: ClockIcon,
  [OVERDUE_STATUS_ID]: AlertTriangleIcon,
  [COMPLETED_STATUS_ID]: CheckIcon,
}

const CREATE_PROJECT_DATE_FIELDS_CONTROLLER = [
  {
    name: 'startDate',
    iconText: 'De',
  },
  {
    name: 'endDate',
    iconText: 'Até',
  },
]

const CREATE_PROJECT_SELECT_FIELDS_CONTROLLER = [
  {
    title: 'Responsável pelo projeto',
    icon: UserIcon,
    name: 'projectResponsible',
  },
  {
    title: 'Observadores',
    icon: EyeIcon,
    name: 'observer',
  },
]

const RESPONSIBLE_FROM_AREAS_FIELDS_CONTROLLER = [
  {
    name: 'area',
    label: 'Área',
    placeholder: 'Selecione a área',
  },
  {
    name: 'responsible',
    label: 'Responsável',
    placeholder: 'Selecione o responsável',
  },
]

const TRANSLATED_PROJECT_ROLES = {
  [OBSERVER_ROLE]: 'Observador',
  [MANAGER_ROLE]: 'Gestor',
  [PROJECT_RESPONSIBLE]: 'Responsável pelo projeto',
  [AREA_RESPONSIBLE]: 'Responsável por área',
  [ACTIVITY_RESPONSIBLE]: 'Responsável por atividade',
}

const QUESTION_RADIO_FIELDS_CONTROLLER = [
  {
    label: 'Sim',
    value: 'yes',
  },
  {
    label: 'Não',
    value: 'no',
  },
  {
    label: 'Parcial',
    value: 'partial',
  },
]

const CHOOSE_STATUS_INDEX_CARD_COLOR = {
  [OVERDUE_STATUS_ID]: colors.red[100],
  [COMPLETED_STATUS_ID]: colors.green[100],
}

const CHOOSE_STATUS_INDEX_CARD_TEXT_COLOR = {
  [OVERDUE_STATUS_ID]: theme.palette.error.main,
  [COMPLETED_STATUS_ID]: theme.palette.success.main,
}

export default {
  ACTIVITY_RESPONSIBLE,
  AREA_RESPONSIBLE,
  BOARD_CATEGORIES_BUTTONS,
  CHOOSE_STATUS_BOARD_COLOR,
  CHOOSE_STATUS_BOARD_ICON,
  CHOOSE_STATUS_INDEX_CARD_COLOR,
  CHOOSE_STATUS_INDEX_CARD_TEXT_COLOR,
  COMPLETED_STATUS_ID,
  CREATE_PROJECT_DATE_FIELDS_CONTROLLER,
  CREATE_PROJECT_SELECT_FIELDS_CONTROLLER,
  IN_PROGRESS_STATUS_ID,
  INACTIVE_STATUS_ID,
  MANAGER_ROLE,
  OBSERVER_ROLE,
  OVERDUE_STATUS_ID,
  PENDING_STATUS_ID,
  PLAN_ACTION_RESPONSIBLE,
  PROJECT_RESPONSIBLE,
  QUESTION_RADIO_FIELDS_CONTROLLER,
  RESPONSIBLE_FROM_AREAS_FIELDS_CONTROLLER,
  TRANSLATED_PROJECT_ROLES,
}
