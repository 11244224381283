import moment from 'moment'

import helpers from 'helpers'

import constants from 'constants/index'
import * as yup from 'yup'

const { EVIDENCE_FILE_TYPES, EVIDENCE_FILE_MIMES_TYPES } = constants.planAction

const currentDay = new Date(new Date().setDate(new Date().getDate() - 1))

const planActionsSchema = ({
  minDate,
  maxDate,
  planActionsOpened,
  activityDeadlineDate,
}) =>
  yup.object().shape({
    id: yup.number(),
    originId: yup.number(),
    status: yup.string(),
    responsible: yup
      .object()
      .shape({
        id: yup.number().required(),
        name: yup.string().required(),
      })
      .required()
      .typeError('Precisa ser uma opção válida'),
    deadlineDate: yup
      .date()
      .typeError('Precisa ser uma data válida')
      .when(['originId'], {
        is: (originId) => planActionsOpened.includes(originId),
        then: yup
          .date()
          .typeError('Precisa ser uma data válida')
          .required()
          .min(
            moment.max(moment(currentDay), moment(minDate).subtract(1, 'day')),
            `O prazo não pode ser anterior à data de hoje nem à data inicial do projeto, ${helpers.formatters.date(
              minDate,
            )}`,
          )
          .max(
            moment(activityDeadlineDate).isValid()
              ? moment.min(moment(maxDate), moment(activityDeadlineDate))
              : maxDate,
            `O prazo não pode ser posterior à data final da atividade ou do projeto, ${helpers.formatters.date(
              moment(activityDeadlineDate).isValid()
                ? moment.min(moment(maxDate), moment(activityDeadlineDate))
                : maxDate,
            )}`,
          ),
      }),
    what: yup.string().max(5000),
    how: yup.string().max(5000),
    because: yup.string().max(5000),
    where: yup.string().max(255),
    howPrice: yup.string().max(18),
  })

const evidenceSchema = yup.object().shape({
  justify: yup.string().max(5000),
  attachments: yup
    .mixed()
    .test('fileSize', 'O arquivo deve ter no máximo 10 MB', (value) =>
      helpers.validates.file.size('MB', 10, value),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        EVIDENCE_FILE_TYPES,
      )} são permitidos`,
      (value) => helpers.validates.file.type(EVIDENCE_FILE_MIMES_TYPES, value),
    ),
})

const schema = ({
  minDate,
  maxDate,
  planActionsOpened,
  activityDeadlineDate,
}) =>
  yup.object().shape({
    responsible: yup
      .object()
      .shape({
        id: yup.number().required(),
        name: yup.string().required(),
      })
      .required()
      .typeError('Precisa ser uma opção válida'),
    deadlineDate: yup
      .date()
      .required()
      .typeError('Precisa ser uma data válida')
      .min(
        moment.max(moment(currentDay), moment(minDate).subtract(1, 'day')),
        `O prazo não pode ser anterior à data de hoje nem à data inicial do projeto, ${helpers.formatters.date(
          minDate,
        )}`,
      )
      .max(
        maxDate,
        `O prazo não pode ser posterior à data final do projeto, ${helpers.formatters.date(
          maxDate,
        )}`,
      ),
    answer: yup.string(),
    evidencePlanActions: yup.array().of(
      yup.object().shape({
        id: yup.number(),
        originId: yup.number(),
        evidence: evidenceSchema,
        planActions: yup.array().of(
          planActionsSchema({
            minDate,
            maxDate,
            planActionsOpened,
            activityDeadlineDate,
          }),
        ),
      }),
    ),
  })

export default schema
