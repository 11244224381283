import React from 'react'
import { Copy as CopyIcon, Info as InfoIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, colors, IconButton, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import useClipboard from 'hooks/useClipboard'

import theme from 'theme'
import useStyles from './styles'
import { getBaseURL } from 'service/env'

const TagManagerDialog = ({ open, setOpen, bannerToken }) => {
  const classes = useStyles()
  const { copy } = useClipboard()

  const htmlURL = `${getBaseURL(
    'cookies',
  )}/banners/install_code?token=${bannerToken}`

  const htmlCode = `<div id="dpoeasy-banner"></div>\n<script src="${htmlURL}"></script>`

  const handleCodeCopying = () => copy(htmlCode)

  return (
    <BaseDialog
      title="Como instalar com Tag Manager"
      open={open}
      setOpen={setOpen}
      fullWidth
      dialogSize="md"
      closeButtonText="Fechar"
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(4)}>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
          <Typography color={theme.palette.neutral.main} variant="h6">
            Configuração via Google Tag Manager do bloqueio das tags e cookies
            de terceiros:
          </Typography>
          <Box
            p={2}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            alignItems="center"
            gridGap={theme.spacing(2)}
          >
            <Box>
              <InfoIcon color={theme.palette.primary.main} />
            </Box>
            <Typography color="primary" mt={1}>
              É necessário você ou um especialista instalar o código do Google
              Tag Manager em seu site.
            </Typography>
          </Box>
          <Typography color={theme.palette.neutral.main} variant="body2">
            Veja se já está instalado. Deve aparecer conforme código abaixo
            com&nbsp;
            <strong>seu id do Google Tag Manager.</strong>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
          <Box bgcolor={colors.grey[300]} borderRadius={1}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" className={classes.divCodeTypography}>
                <span className={classes.tagRed}>&lt;div</span>
                <span className={classes.tagOrange}> id="</span>
                <span className={classes.tagBlue}>dpoeasy-banner</span>
                <span className={classes.tagOrange}>"</span>
                <span className={classes.tagRed}>&gt;&lt;/div&gt;</span>
              </Typography>
              <Box height="fit-content">
                <IconButton
                  size="small"
                  className={classes.iconButton}
                  onClick={handleCodeCopying}
                >
                  <CopyIcon size={18} color={theme.palette.custom.superDark} />
                </IconButton>
              </Box>
            </Box>
            <Typography
              variant="body2"
              className={classes.scriptCodeTypography}
            >
              <span className={classes.tagRed}>&lt;script</span>
              <span className={classes.tagOrange}> src="</span>
              <span className={classes.tagBlue}>{htmlURL}</span>
              <span className={classes.tagOrange}>"</span>
              <span className={classes.tagRed}>&gt;&lt;/script&gt;</span>
            </Typography>
          </Box>
          <Typography color={theme.palette.neutral.main} variant="body2">
            Vá até a seção de <strong>Validação de Cookies.</strong>
          </Typography>
        </Box>
        <Box color={theme.palette.neutral.main} textAlign="justify">
          <Typography variant="body2" gutterBottom>
            Copie o "Código HTML da sua Tag - (Tag 1)", que pode ser encontrado
            logo no primeiro campo dessa seção. Vale lembrar que geramos uma tag
            única para cada cookie criado.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Copie o código do Google Tag Manager (ID).
          </Typography>
          <Typography variant="body2" className={classes.orderedList}>
            <ol>
              <li>Acesse seu GTM: Google Tag Manager</li>
              <li>
                Crie uma tag. Em seu Google Tag Manager, crie uma nova tag
                clicando em Novo &gt; HTML personalizado. No campo HTML cole o
                código do DPOEasy. Para você identificar a tag depois, coloque
                um nome na parte superior da página (exemplo: “Tag Banner de
                Cookie”)
              </li>
              <li>
                Acionador: Defina o disparo em todas as páginas / All Pages Na
                parte de “x”, escolha todas as páginas
              </li>
              <li>Salve/Guardar as alterações</li>
              <li>Clique em “Salvar/Guardar” e a tag estará configurada.</li>
              <li>
                Publique/Envie as alterações Não basta salvar é necessário
                publicar as alterações para que o aviso apareça em seu site.
              </li>
              <li>Clique em Submit e depois publicar.</li>
            </ol>
          </Typography>
        </Box>
      </Box>
    </BaseDialog>
  )
}

TagManagerDialog.propTypes = {
  bannerToken: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default TagManagerDialog
