import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(1),
  },
  gridItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(1),
  },
  responsibleCard: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 4,
  },
}))

export default styles
