import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  rootBreakpoints: {
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  childrenContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    '& button': {
      whiteSpace: 'nowrap',
      width: 'auto',
    },
  },
  childrenContainerBreakpoints: {
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
      gridRow: '2',
      flexDirection: 'column',
      width: '100%',
      '& button': {
        width: '100%',
      },
    },
  },
  h3: {
    fontWeight: 600,
  },
  textLighten: {
    color: theme.palette.common.white,
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}))

export default useStyles
