import { isEmpty } from 'lodash'

const PermittedByAssignmentRole = ({
  children,
  permittedRoles = [],
  unpermittedRoles = [],
  userRole,
}) => {
  if (!isEmpty(unpermittedRoles) && unpermittedRoles.includes(userRole)) {
    return null
  }

  if (isEmpty(unpermittedRoles) && permittedRoles.includes(userRole)) {
    return children
  }

  if (!isEmpty(unpermittedRoles) && !unpermittedRoles.includes(userRole)) {
    return children
  }

  return null
}

export default PermittedByAssignmentRole
