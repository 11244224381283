import React from 'react'
import PropTypes from 'prop-types'
import { Box, LinearProgress, Paper, Typography } from '@material-ui/core'

import theme from 'theme'
import useStyles from './styles'

const EvidenceCard = ({ title, value, progressValue, ...rest }) => {
  const classes = useStyles()

  return (
    <Paper>
      <Box className={classes.evidenceCard} {...rest}>
        <Box
          display="flex"
          gridGap={theme.spacing(2)}
          justifyContent="space-between"
          alignItems="end"
        >
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2">{value}</Typography>
        </Box>
        <Box width="100%">
          <LinearProgress
            variant="determinate"
            className={classes.lineBar}
            value={progressValue}
          />
        </Box>
      </Box>
    </Paper>
  )
}

EvidenceCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  progressValue: PropTypes.number.isRequired,
}

export default EvidenceCard
