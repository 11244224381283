const getAssignmentsByRole = (
  assignments = [],
  role = undefined,
  format = false,
) => {
  if (!role) {
    return assignments
  }
  const filteredAssignments = assignments.filter(
    (assignment) => assignment.role === role,
  )

  if (format) {
    return filteredAssignments.map((assignment) => assignment.user)
  }

  return filteredAssignments
}

export default getAssignmentsByRole
