import React, { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Grid, TextField, Typography } from '@material-ui/core'

const SummaryFormItems = ({
  disabled = false,
  index,
  name,
  users,
  planAction,
  componentIndex,
  governanceProject,
}) => {
  const { control, errors, watch } = useFormContext()

  const componentError = errors?.evidencePlanActions?.[componentIndex]

  const activityDeadlineDate = watch('deadlineDate')
  const endDate = moment(governanceProject?.endDate)
  const minDate = moment(governanceProject?.startDate)
  const maxDate = moment.min(endDate, moment(activityDeadlineDate))

  return (
    <Fragment>
      <Controller
        as={<Fragment />}
        name={`${name}.planActions.${index}.status`}
        control={control}
      />
      <Controller
        as={<Fragment />}
        name={`${name}.planActions.${index}.originId`}
        control={control}
      />
      <Grid item xs={12} md={6}>
        <Typography variant="h5" gutterBottom>
          Responsável
        </Typography>
        <Controller
          as={
            <Autocomplete
              options={users}
              getOptionLabel={(option) => option?.name || ''}
              getOptionSelected={(option, value) =>
                option.id === value.id || value.id === 0
              }
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!componentError?.planActions?.[index]?.responsible}
                  helperText={
                    componentError?.planActions?.[index]?.responsible
                      ?.message ||
                    componentError?.planActions?.[index]?.responsible?.id
                      ?.message
                  }
                  disabled={disabled}
                />
              )}
            />
          }
          control={control}
          name={`${name}.planActions.${index}.responsible`}
          mode="onChange"
          onChange={([, data]) => data}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" gutterBottom>
          Prazo
        </Typography>
        <Controller
          as={
            <KeyboardDatePicker
              inputVariant="outlined"
              format="DD/MM/yyyy"
              error={!!componentError?.planActions?.[index]?.deadlineDate}
              helperText={
                componentError?.planActions?.[index]?.deadlineDate?.message
              }
              onChange={(newValue) => ({ value: newValue })}
              disabled={disabled}
              minDate={minDate}
              maxDate={maxDate}
              disablePast
              fullWidth
            />
          }
          control={control}
          name={`${name}.planActions.${index}.deadlineDate`}
          mode="onChange"
          defaultValue={planAction?.deadlineDate}
          onChange={([, data]) => moment(data, 'DD/MM/yyyy')}
        />
      </Grid>
    </Fragment>
  )
}

SummaryFormItems.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  users: PropTypes.array,
  planAction: PropTypes.object,
  governanceProject: PropTypes.object,
  componentIndex: PropTypes.number.isRequired,
}

export default SummaryFormItems
