import calcDays from './calcDays'
import cardFlag from './cardFlag'
import cep from './cep'
import cnpj from './cnpj'
import cnpjOrCpf from './cnpjOrCpf'
import convertBinaryToBool from './convertBinaryToBool'
import convertBoolToBinary from './convertBoolToBinary'
import convertBoolToString from './convertBoolToString'
import cpf from './cpf'
import creditCardNumber from './creditCardNumber'
import currencyInput from './currencyInput'
import date from './date'
import dateFromNow from './dateFromNow'
import dateLocaleFormat from './dateLocaleFormat'
import definedErrorMessage from './definedErrorMessage'
import demoEndDateFormatter from './demoEndDateFormatter'
import documentFormatter from './documentFormatter'
import dynamicImageSize from './dynamicImageSize'
import errorMessage from './errorMessage'
import expirationDateCard from './expirationDateCard'
import file from './file'
import float from './float'
import formatDataTomticket from './formatDataTomticket'
import formatDataTomticketRequestPartner from './formatDataTomticketRequestPartner'
import formattedConstantName from './formattedConstantName'
import formatToCurrencyBrl from './formatToCurrencyBrl'
import getMethodIdByBrand from './getMethodIdByBrand'
import hours from './hours'
import htmlToPlainText from './htmlToPlainText'
import money from './money'
import numberToPrice from './numberToPrice'
import onlyNumbers from './onlyNumbers'
import phone from './phone'
import phoneDynamicMask from './phoneDynamicMask'
import phoneMaskBuilder from './phoneMaskBuilder'
import phoneWithoutCountryCode from './phoneWithoutCountryCode'
import plansToSupplierForm from './plansToSupplierForm'
import ptBrFormatDateTime from './ptBrFormatDateTime'
import stylesForSocialMedia from './stylesForSocialMedia'
import toISOStringVerify from './toISOStringVerify'
import url from './url'
import website from './website'

const formatters = {
  calcDays,
  cardFlag,
  cep,
  cnpj,
  cnpjOrCpf,
  convertBinaryToBool,
  convertBoolToBinary,
  convertBoolToString,
  cpf,
  creditCardNumber,
  currencyInput,
  date,
  dateFromNow,
  dateLocaleFormat,
  definedErrorMessage,
  demoEndDateFormatter,
  documentFormatter,
  dynamicImageSize,
  errorMessage,
  expirationDateCard,
  file,
  float,
  formatDataTomticket,
  formatDataTomticketRequestPartner,
  formattedConstantName,
  formatToCurrencyBrl,
  getMethodIdByBrand,
  hours,
  htmlToPlainText,
  money,
  numberToPrice,
  onlyNumbers,
  phone,
  phoneDynamicMask,
  phoneMaskBuilder,
  phoneWithoutCountryCode,
  plansToSupplierForm,
  ptBrFormatDateTime,
  stylesForSocialMedia,
  toISOStringVerify,
  url,
  website,
}

export default formatters
