import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    display: 'flex',
    gridGap: theme.spacing(3),
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  comumContainer: {
    display: 'flex',
    gridGap: theme.spacing(3),
    alignItems: 'center',
  },
}))

export default styles
