import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core'

import helpers from 'helpers'

import constants from 'constants/index'

const DetailsPlanForm = ({ name, index, disabled = false, componentIndex }) => {
  const { PLAN_ACTION_FIELDS_CONTROLLER } = constants.planAction

  const { control, errors } = useFormContext()

  const componentError = errors?.evidencePlanActions?.[componentIndex]

  const handleOnChange = (controlName, value) => {
    if (controlName === 'howPrice') return helpers.formatters.money.input(value)
    return value
  }

  return PLAN_ACTION_FIELDS_CONTROLLER.map((field, fieldIndex) => (
    <Grid key={fieldIndex} item xs={12} md={field?.md}>
      <Typography variant="h5" gutterBottom>
        {field?.label}
      </Typography>
      <Controller
        as={
          <TextField
            variant="outlined"
            error={!!componentError?.planActions?.[index]?.[field?.name]}
            helperText={
              componentError?.planActions?.[index]?.[field?.name]?.message
            }
            multiline={field?.rows ? true : false}
            placeholder={field?.placeholder}
            minRows={field?.rows}
            maxRows={field?.rows}
            fullWidth
            InputProps={{
              inputMode: field?.inputMode || 'text',
              ...(field?.startIcon && {
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography color="secondary">
                      {field?.startIcon}
                    </Typography>
                  </InputAdornment>
                ),
              }),
            }}
            disabled={disabled}
          />
        }
        control={control}
        name={`${name}.planActions.${index}.${field?.name}`}
        mode="onChange"
        onChange={([event]) =>
          handleOnChange(field?.name, event?.target?.value)
        }
      />
    </Grid>
  ))
}

DetailsPlanForm.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  componentIndex: PropTypes.number.isRequired,
}

export default DetailsPlanForm
