import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  containerIcon: {
    color: theme.palette.common.white,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  categoryButton: {
    display: 'flex',
    gridGap: theme.spacing(2),
    padding: 10,
    justifyContent: 'space-between',
    borderColor: theme.palette.custom.border,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
  },
  categoryIcon: {
    padding: 4,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  gridItem: {
    display: 'flex',
  },
  boardScrollbar: {
    overflow: 'auto',
    paddingBottom: theme.spacing(2),
  },
  boardHeader: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: 'fit-content',
    color: theme.palette.common.white,
  },
  boardContent: {
    flex: 1,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    paddingLeft: theme.spacing(2),
    paddingBlock: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
  },
  scrollbar: {
    paddingRight: theme.spacing(2),
    height: '100%',
  },
  boardBody: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(1),
    width: 350,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 250,
    },
  },
  notFoundActivity: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
}))

export default styles
