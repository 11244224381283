import React from 'react'

import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'

import {
  AlertTriangle,
  Grid as GridIcon,
  Settings as SettingsIcon,
  Watch as WatchIcon,
} from 'react-feather'

import { PermittedByAssignmentRole } from 'components'
import { PeopleCard } from './components'
import { EvidenceCard, RadialGraphicsCard } from '..'

import helpers from 'helpers'

import { find } from 'lodash'

import theme from 'theme'

import useAuth from 'hooks/useAuth'
import usePermissions from 'hooks/usePermissions'

import constants from 'constants/index'
import useStyles from './styles'

const SummaryCard = ({
  governanceProject,
  handleProjectManagerRedirection,
  handleOpenModal,
  handleOpenFinalizedModal,
}) => {
  const { PENDING_STATUS_ID } = constants.governanceProject

  const { percentage } = helpers.functions

  const { user } = useAuth()
  const { permitted } = usePermissions()

  const overdueActivity = governanceProject?.activities?.overdue > 0

  const classes = useStyles({
    projectStatus: governanceProject?.status,
    overdueActivity,
  })()

  const totalQuestion = governanceProject?.questions?.total ?? 0
  const answeredQuestion = governanceProject?.questions?.answered ?? 0

  const totalEvidences = governanceProject?.evidences?.total ?? 0
  const attachedEvidence = governanceProject?.evidences?.attached ?? 0

  const generalProgress = governanceProject?.generalProgress ?? 0

  const assignments = helpers.governanceProject.orderAndClearAssignments(
    governanceProject?.projectAssignments,
  )

  const managePermitted = permitted(
    constants.permissions.GOVERNANCE_PROJECTS.CREATE,
  )
  const assignmentRole = managePermitted
    ? 'manager'
    : find(assignments, { user: { id: user?.id } })?.role

  return (
    <Card>
      <Box className={classes.cardContainer}>
        <Tooltip title={governanceProject?.name}>
          <Typography variant="h5" noWrap>
            {governanceProject?.name}
          </Typography>
        </Tooltip>
        <PermittedByAssignmentRole
          userRole={assignmentRole}
          permittedRoles={['manager', 'project_responsible']}
        >
          <Box display="flex" gridGap={theme.spacing(2)}>
            {governanceProject?.status === PENDING_STATUS_ID && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpenFinalizedModal(governanceProject)}
              >
                Finalizar projeto
              </Button>
            )}
            <Tooltip title="Configurações">
              <IconButton
                color="primary"
                onClick={() => handleOpenModal(governanceProject)}
              >
                <SettingsIcon size={20} />
              </IconButton>
            </Tooltip>
          </Box>
        </PermittedByAssignmentRole>
      </Box>
      <Divider />
      <CardActionArea
        onClick={() => handleProjectManagerRedirection(governanceProject?.id)}
      >
        <Box className={classes.cardActionContainer}>
          <Box display="flex" gridGap={theme.spacing(2)} p={2}>
            <Box>
              <GridIcon />
            </Box>
            <Tooltip
              title={`Template - ${governanceProject?.origin?.name}`}
              placement="top"
            >
              <Typography variant="h6" noWrap>
                Template - {governanceProject?.origin?.name}
              </Typography>
            </Tooltip>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className={classes.dateCard}>
                <Box>
                  <WatchIcon />
                </Box>
                <Typography variant="h6">
                  {helpers.formatters.date(governanceProject?.endDate)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.activityCard}>
                <Box
                  display="flex"
                  gridGap={theme.spacing(2)}
                  alignItems="center"
                >
                  <Box>
                    <AlertTriangle />
                  </Box>
                  <Typography variant="h6">Atividades Atrasadas</Typography>
                </Box>
                <Typography variant="h6">
                  {governanceProject?.activities?.overdue}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <RadialGraphicsCard
            title="Progresso Geral"
            subtitle="Pergunta respondida + Evidência anexada"
            percentage={generalProgress}
          />
          <RadialGraphicsCard
            title="Perguntas Respondidas"
            subtitle={`Sua organização respondeu ${answeredQuestion}/${totalQuestion} questões.`}
            value={answeredQuestion}
            percentage={percentage(answeredQuestion, totalQuestion)}
            onlyNumber
          />
          <EvidenceCard
            title="Evidências anexadas"
            value={`${attachedEvidence}/${totalEvidences}`}
            progressValue={percentage(attachedEvidence, totalEvidences)}
          />
          <PeopleCard
            governanceProject={governanceProject}
            handleOpenModal={handleOpenModal}
            assignments={assignments}
            assignmentRole={assignmentRole}
          />
        </Box>
      </CardActionArea>
    </Card>
  )
}

SummaryCard.propTypes = {
  governanceProject: PropTypes.object,
  handleProjectManagerRedirection: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
}

export default SummaryCard
