import { makeStyles } from '@material-ui/core'

const styles = (color) =>
  makeStyles((theme) => ({
    containerIcon: {
      color: theme.palette.common.white,
      backgroundColor: color,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
    },
    categoryIcon: {
      padding: 4,
    },
    cardItemBoard: {
      border: `1px solid ${color}`,
      borderRadius: 4,
      backgroundColor: theme.palette.background.default,
      width: '100%',
    },
    cardActionArea: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gridGap: theme.spacing(1),
    },
    typography: {
      lineHeight: 1,
    },
    paralelogramoContainer: {
      paddingBlock: 4,
      paddingLeft: 4,
      paddingRight: 6,
      transform: 'skew(-20deg)',
      // backgroundColor: color,
    },
    paralelogramo: {
      transform: 'skew(20deg)',
      display: 'flex',
      gridGap: theme.spacing(1),
      alignItems: 'center',
      // color: theme.palette.common.white,
    },
  }))

export default styles
