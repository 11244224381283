import { find } from 'lodash'

import constants from 'constants/index'

const useAssignmentPermission = ({
  governanceProject,
  assignments = [],
  userId = 0,
}) => {
  const {
    OBSERVER_ROLE,
    MANAGER_ROLE,
    PROJECT_RESPONSIBLE,
    AREA_RESPONSIBLE,
    ACTIVITY_RESPONSIBLE,
    PLAN_ACTION_RESPONSIBLE,
  } = constants.governanceProject

  const assignmentRole =
    governanceProject?.assignmentRole ||
    find(assignments, { user: { id: userId } })?.role

  const isObserver = assignmentRole === OBSERVER_ROLE
  const isManager = assignmentRole === MANAGER_ROLE
  const isProjectResponsible = assignmentRole === PROJECT_RESPONSIBLE
  const isAreaResponsible = assignmentRole === AREA_RESPONSIBLE
  const isActivityResponsible = assignmentRole === ACTIVITY_RESPONSIBLE
  const isPlanActionResponsible = assignmentRole === PLAN_ACTION_RESPONSIBLE

  const permitted = (roles = []) => roles.includes(assignmentRole)
  const unpermitted = (roles = []) => !roles.includes(assignmentRole)

  return {
    permitted,
    unpermitted,
    assignmentRole,
    isObserver,
    isManager,
    isProjectResponsible,
    isAreaResponsible,
    isActivityResponsible,
    isPlanActionResponsible,
  }
}

export default useAssignmentPermission
