import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gridGap: theme.spacing(2),
  },
  headContainer: {
    display: 'flex',
    gridGap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    textTransform: 'initial',
    gridGap: theme.spacing(1),
  },
  weightTypography: {
    fontWeight: 600,
  },
  scrollbar: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  chip: {
    maxWidth: 200,
    flexShrink: 1,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  innerContainer: {
    display: 'flex',
    gridGap: theme.spacing(3),
    alignItems: 'center',
  },
  responsibleText: {
    width: 250,
  },
}))

export default styles
