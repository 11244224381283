import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  colors,
  Grid,
  lighten,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import { PermittedByAssignmentRole, RadialGradientChart } from 'components'
import { EvidenceCard, RadialGraphicsCard } from '..'

import helpers from 'helpers'

import useAssignmentPermission from 'hooks/useAssignmentPermission'

import theme from 'theme'
import useStyles from './styles'

const InformationCard = ({
  governanceProject,
  questionnaires,
  requestIsLoading,
}) => {
  const { percentage } = helpers.functions

  const classes = useStyles()

  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  })

  const { permitted } = useAssignmentPermission({ governanceProject })

  const permittedAreaResponsible = permitted([
    'area_responsible',
    'activity_responsible',
  ])

  return (
    <Paper className={classes.container}>
      <Grid container spacing={2} className={classes.gridContainer}>
        <PermittedByAssignmentRole
          unpermittedRoles={['area_responsible', 'activity_responsible']}
          userRole={governanceProject?.assignmentRole}
        >
          <Grid item xs={12} lg={4} className={classes.gridItem}>
            <Typography variant="h5">Progresso</Typography>
            <Paper className={classes.chartContainer}>
              {requestIsLoading ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width="100%"
                  height={isXsDown ? 300 : 370}
                />
              ) : (
                <RadialGradientChart
                  trackBackground={colors.grey['A100']}
                  value={governanceProject?.progress}
                  width={300}
                  height={isXsDown ? 300 : 370}
                  containerProps={{ display: 'flex', justifyContent: 'center' }}
                  primaryColor={colors.teal[900]}
                  secondaryColor={lighten(colors.green[900], 0.5)}
                />
              )}
            </Paper>
          </Grid>
        </PermittedByAssignmentRole>
        <Grid
          item
          xs={12}
          lg={permittedAreaResponsible ? 7 : 5}
          className={classes.gridItem}
        >
          <Typography variant="h5">Resolução dos questionários</Typography>
          <Box height="100%">
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
              className={classes.scrollbar}
            >
              <Box
                display="flex"
                flexDirection="column"
                gridGap={theme.spacing(2)}
                maxHeight={300}
              >
                {requestIsLoading
                  ? [...Array(4)].map((_, index) => (
                      <Box key={index}>
                        <Skeleton variant="rect" animation="wave" height={84} />
                      </Box>
                    ))
                  : questionnaires?.map((questionnaire) => (
                      <RadialGraphicsCard
                        key={questionnaire?.id}
                        title={questionnaire?.kind}
                        subtitle={`Sua organização respondeu ${questionnaire?.questions?.answered}/${questionnaire?.questions?.total} questões.`}
                        value={questionnaire?.questions?.answered ?? 0}
                        percentage={percentage(
                          questionnaire?.questions?.answered,
                          questionnaire?.questions?.total,
                        )}
                        onlyNumber
                      />
                    ))}
              </Box>
            </PerfectScrollbar>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={permittedAreaResponsible ? 5 : 3}
          className={classes.gridItem}
        >
          <Typography variant="h5">Evidências</Typography>
          <Box height="100%">
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
              className={classes.scrollbar}
            >
              <Box
                display="flex"
                flexDirection="column"
                gridGap={theme.spacing(2)}
                maxHeight={300}
              >
                {requestIsLoading
                  ? [...Array(4)].map((_, index) => (
                      <Box key={index}>
                        <Skeleton variant="rect" animation="wave" height={84} />
                      </Box>
                    ))
                  : questionnaires?.map((questionnaire) => (
                      <EvidenceCard
                        key={questionnaire?.id}
                        title={questionnaire?.kind}
                        value={`${questionnaire?.evidences?.completed}/${questionnaire?.evidences?.total}`}
                        progressValue={percentage(
                          questionnaire?.evidences?.completed,
                          questionnaire?.evidences?.total,
                        )}
                        minHeight={83}
                      />
                    ))}
              </Box>
            </PerfectScrollbar>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

InformationCard.propTypes = {
  governanceProject: PropTypes.object,
  questionnaires: PropTypes.arrayOf(PropTypes.object),
  requestIsLoading: PropTypes.bool.isRequired,
}

export default InformationCard
