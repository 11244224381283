import React, { Fragment, useState } from 'react'
import { Box, Button, IconButton, Tooltip } from '@material-ui/core'
import {
  HelpCircle as HelpCircleIcon,
  Settings as SettingsIcon,
} from 'react-feather'

import {
  AlertDialog,
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
  PermittedByAssignmentRole,
} from 'components'
import {
  InformationCard,
  ProjectBoard,
  ProjectSettingsModal,
} from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const GovernanceProjectsManage = ({ match }) => {
  const { governanceProjectId } = match.params
  const { PENDING_STATUS_ID } = constants.governanceProject

  const [openModal, setOpenModal] = useState(false)
  const [openFinalizedModal, setOpenFinalizedModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const {
    response: projectResponse,
    isLoading: projectIsLoading,
    refresh: projectRefresh,
  } = useFetch(
    service.dponet.governanceProject.get,
    {
      governanceProjectId,
    },
    [],
  )

  const governanceProject = projectResponse?.data?.governanceProject

  const {
    response: questionnairesResponse,
    isLoading: questionnairesIsLoading,
    refresh: questionnairesRefresh,
  } = useFetch(
    service.dponet.governanceProjectQuestionnaire.resume,
    {
      governanceProjectId,
      order: 'kind ASC',
    },
    [],
  )

  const questionnaires =
    questionnairesResponse?.data?.governanceProjectQuestionnaires

  const { response: usersResponse, isLoading: usersIsLoading } = useFetch(
    service.dponet.users.get,
    {
      perPage: 10000,
      minimal: true,
      active: true,
      userCompanyStatus: constants.userCompanies.ACTIVE,
    },
  )

  const users = usersResponse?.data?.users

  const { response: templateResponse, isLoading: templateIsLoading } = useFetch(
    service.dponet.governanceProjectTemplate.get,
    {},
    [],
  )

  const projectTemplates = templateResponse?.data?.governanceProjectTemplates

  const requestIsLoading =
    projectIsLoading ||
    questionnairesIsLoading ||
    usersIsLoading ||
    templateIsLoading

  const handleOpenDialog = () => setOpenModal((prevState) => !prevState)

  const handleRefresh = () => {
    projectRefresh()
    questionnairesRefresh()
  }

  const handleOpenFinalizedModal = () =>
    setOpenFinalizedModal((prevState) => !prevState)

  const handleFinalizedProject = async () => {
    try {
      setLoading(true)

      await service.dponet.governanceProject.changeStatus({
        governanceProjectId: governanceProject.id,
        status: constants.governanceProject.COMPLETED_STATUS_ID,
      })

      snackbar.open({
        message: 'Projeto finalizado com sucesso!',
        variant: 'success',
      })
      handleRefresh()
      handleOpenFinalizedModal()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page title={governanceProject?.name || 'Projeto de Governança'}>
      <LoadingFeedback open={loading} />
      <Container maxWidth={false}>
        <ContentHeader
          title={governanceProject?.name || 'Projeto de Governança'}
          goBack
        >
          {/* <Box>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                id={MAIN_STEP_1}
                fullWidth
              >
                Tutorial
              </Button>
            </Tooltip>
          </Box> */}
          <PermittedByAssignmentRole
            permittedRoles={['manager', 'project_responsible']}
            userRole={governanceProject?.assignmentRole}
          >
            {governanceProject?.status === PENDING_STATUS_ID && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenFinalizedModal}
              >
                Finalizar projeto
              </Button>
            )}
            <Tooltip title="Configurações">
              <IconButton color="primary" onClick={handleOpenDialog}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </PermittedByAssignmentRole>
        </ContentHeader>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(4)}>
          <InformationCard
            governanceProject={governanceProject}
            questionnaires={questionnaires}
            requestIsLoading={requestIsLoading}
          />
          <ProjectBoard
            questionnaires={questionnaires}
            governanceProjectId={governanceProjectId}
            requestIsLoading={requestIsLoading}
          />
        </Box>
        {openModal && (
          <ProjectSettingsModal
            open={openModal}
            setOpen={handleOpenDialog}
            setLoading={setLoading}
            isEdit
            users={users}
            refresh={handleRefresh}
            governanceProject={governanceProject}
            projectTemplates={projectTemplates}
          />
        )}
        {openFinalizedModal && (
          <AlertDialog
            open={openFinalizedModal}
            onClose={handleOpenFinalizedModal}
            variant="standard"
            severity="success"
            title="Finalizar projeto de governança"
            content="Tem certeza de que deseja finalizar este projeto de governança? Após a finalização, não será possível reabri-lo!"
            dialogActions={
              <Fragment>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.outlinedSuccessButton}
                  onClick={handleOpenFinalizedModal}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.containerSuccessButton}
                  onClick={handleFinalizedProject}
                >
                  Finalizar
                </Button>
              </Fragment>
            }
          />
        )}
      </Container>
    </Page>
  )
}

export default GovernanceProjectsManage
