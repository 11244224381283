const hexToRgba = (hex, alpha = 1.0) => {
  if (!/^#?([a-f\d]{3}|[a-f\d]{4}|[a-f\d]{6}|[a-f\d]{8})$/i.test(hex))
    return undefined

  hex = hex.replace(/^#/, '')

  if (hex.length === 3 || hex.length === 4) {
    hex = hex
      .split('')
      .map(function (char) {
        return char + char
      })
      .join('')
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export default hexToRgba
