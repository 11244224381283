import React, { useEffect, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Clock as ClockIcon,
  PieChart as PieChartIcon,
  TrendingUp as TrendingUpIcon,
  Grid as GridIcon,
  FileText as FileTextIcon,
  User as UserIcon,
} from 'react-feather'
import moment from 'moment'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  Box,
  colors,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'

import { AlertDialog, BaseDialog, Select } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'
import schema from './schema'
import theme from 'theme'

const ProjectSettingsModal = ({
  open,
  setOpen,
  setLoading,
  refresh = () => {},
  governanceProject,
  users = [],
  projectTemplates = [],
  isEdit = false,
}) => {
  const {
    CREATE_PROJECT_DATE_FIELDS_CONTROLLER,
    CREATE_PROJECT_SELECT_FIELDS_CONTROLLER,
    RESPONSIBLE_FROM_AREAS_FIELDS_CONTROLLER,
    COMPLETED_STATUS_ID,
    INACTIVE_STATUS_ID,
  } = constants.governanceProject

  const [openAlertDialog, setOpenAlertDialog] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()
  const alertDialogRef = useRef()

  const observers = helpers.governanceProject.getAssignmentsByRole(
    governanceProject?.projectAssignments,
    'observer',
    true,
  )
  const projectResponsible = helpers.governanceProject.getAssignmentsByRole(
    governanceProject?.projectAssignments,
    'project_responsible',
    true,
  )

  const { control, errors, handleSubmit, reset, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: governanceProject?.name || '',
      purpose: governanceProject?.purpose || '',
      startDate: governanceProject?.startDate || null,
      endDate: governanceProject?.endDate || null,
      originId: governanceProject?.origin?.id || '',
      projectResponsible: projectResponsible || [],
      observer: observers || [],
      areas: governanceProject?.areaResponsible || [],
    },
  })

  const { fields } = useFieldArray({
    control,
    name: 'areas',
  })

  const originId = watch('originId')
  const startDate = watch('startDate')

  const isCompleted = governanceProject?.status === COMPLETED_STATUS_ID
  const isInactive = governanceProject?.status === INACTIVE_STATUS_ID

  const isOriginSelectTemplate = governanceProject?.origin?.id === originId

  const selectedTemplate =
    projectTemplates?.find((template) => template.id === originId) || []

  const areas =
    selectedTemplate?.areas?.map((area) => ({
      id: area.id,
      name: area.kind,
    })) || []

  const defaultArea =
    selectedTemplate?.areas?.map((area) => ({
      area: { id: area?.id, name: area?.kind },
      responsible: null,
    })) || []

  const handleOpenAlertDialog = () => {
    if (!alertDialogRef.current) {
      setOpenAlertDialog(true)
      alertDialogRef.current = true
    }
  }
  const handleCloseAlertDialog = () => setOpenAlertDialog(false)

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      const { areas, ...baseData } = data

      const transformedData = {
        ...baseData,
        startDate: helpers.formatters.dateLocaleFormat(data.startDate),
        endDate: helpers.formatters.dateLocaleFormat(data.endDate),
        projectResponsible: data.projectResponsible.map((user) => user.id),
        observer: data.observer.map((observer) => observer.id),
        areaResponsible: areas.map((group) => ({
          questionnaireId: group.area?.id,
          responsibleId: group.responsible?.id,
        })),
      }

      if (isEdit) {
        await service.dponet.governanceProject.update({
          governanceProjectId: governanceProject?.id,
          ...transformedData,
        })
      } else {
        await service.dponet.governanceProject.create(transformedData)
      }

      reset()
      refresh()
      setOpen()
      snackbar.open({
        message: `Projeto ${isEdit ? 'editado' : 'criado'} com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isEmpty(defaultArea)) {
      reset({
        ...watch(),
        areas: isOriginSelectTemplate
          ? governanceProject?.areaResponsible
          : defaultArea,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originId])

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title={`${isEdit ? 'Editar' : 'Criar'} projeto`}
      dialogSize="md"
      closeButtonText="Cancelar"
      actionButtonText={`${isEdit ? 'Salvar' : 'Criar'}`}
      formRef="create-governance-project-form"
      justifyActions="flex-end"
      preventCloseOnBackdropClick
      disableButton={isCompleted || isInactive}
      fullWidth
    >
      <Box
        component="form"
        id="create-governance-project-form"
        onSubmit={handleSubmit(onSubmit)}
        py={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.gridItemContainer}>
            <Box
              className={classes.titleContainer}
              color={
                !!errors?.name
                  ? theme.palette.error.main
                  : theme.palette.primary.main
              }
            >
              <Box>
                <PieChartIcon size={20} />
              </Box>
              <Typography variant="h6">Nome do projeto</Typography>
            </Box>
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  disabled={isCompleted || isInactive}
                  fullWidth
                />
              }
              control={control}
              mode="onChange"
              name="name"
            />
          </Grid>
          <Grid item xs={12} className={classes.gridItemContainer}>
            <Box
              className={classes.titleContainer}
              color={
                !!errors?.startDate || !!errors?.endDate
                  ? theme.palette.error.main
                  : theme.palette.primary.main
              }
            >
              <Box>
                <ClockIcon size={20} />
              </Box>
              <Typography variant="h6">Período do projeto</Typography>
            </Box>
            <Grid container spacing={2}>
              {CREATE_PROJECT_DATE_FIELDS_CONTROLLER.map((field, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <Controller
                    as={
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        format="DD/MM/yyyy"
                        error={!!errors?.[field?.name]}
                        helperText={errors?.[field?.name]?.message}
                        onChange={(newValue) => ({ value: newValue })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography color="secondary">
                                {field?.iconText}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        {...(field?.name === 'endDate' && {
                          disablePast: true,
                          ...(startDate && {
                            minDate: moment(startDate),
                          }),
                          onClick: handleOpenAlertDialog,
                        })}
                        disabled={isCompleted || isInactive}
                        fullWidth
                      />
                    }
                    control={control}
                    mode="onChange"
                    name={field?.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {CREATE_PROJECT_SELECT_FIELDS_CONTROLLER.map((field, index) => (
            <Grid
              key={index}
              item
              xs={12}
              className={classes.gridItemContainer}
            >
              <Box
                className={classes.titleContainer}
                color={
                  !!errors?.[field?.name]
                    ? theme.palette.error.main
                    : theme.palette.primary.main
                }
              >
                <Box>
                  <field.icon size={20} />
                </Box>
                <Typography variant="h6">{field?.title}</Typography>
              </Box>
              <Controller
                as={
                  <Autocomplete
                    multiple
                    options={users}
                    getOptionLabel={(option) => option?.name}
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id || value?.id === 0
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors?.[field?.name]}
                        helperText={errors?.[field?.name]?.message}
                      />
                    )}
                    disabled={isCompleted || isInactive}
                  />
                }
                control={control}
                mode="onChange"
                name={field?.name}
                onChange={([_, data]) => data}
              />
            </Grid>
          ))}
          <Grid item xs={12} className={classes.gridItemContainer}>
            <Box
              className={classes.titleContainer}
              color={
                !!errors?.originId
                  ? theme.palette.error.main
                  : theme.palette.primary.main
              }
            >
              <Box>
                <GridIcon size={20} />
              </Box>
              <Typography variant="h6">Template de projeto</Typography>
            </Box>
            <Controller
              as={
                <Select
                  items={projectTemplates}
                  error={!!errors?.originId}
                  helperText={errors?.originId?.message}
                  disabled={isEdit || isCompleted || isInactive}
                />
              }
              control={control}
              mode="onChange"
              name="originId"
            />
          </Grid>
          {!!originId && (
            <Grid item xs={12} className={classes.gridItemContainer}>
              <Box
                className={classes.responsibleCard}
                bgcolor={
                  !!errors?.areas
                    ? colors.red[50]
                    : theme.palette.background.default
                }
              >
                <Box
                  className={classes.titleContainer}
                  color={
                    !!errors?.areas
                      ? theme.palette.error.main
                      : theme.palette.primary.main
                  }
                >
                  <Box>
                    <FileTextIcon size={20} />
                  </Box>
                  <Typography variant="h6">Responsáveis por área</Typography>
                </Box>
                {fields?.map((field, index) => (
                  <Grid key={field?.id} container spacing={2}>
                    {RESPONSIBLE_FROM_AREAS_FIELDS_CONTROLLER.map(
                      (item, itemIndex) => {
                        const isArea = item?.name === 'area'
                        return (
                          <Grid key={itemIndex} item xs={12} md={6}>
                            <Controller
                              as={
                                <Autocomplete
                                  options={isArea ? areas : users}
                                  getOptionLabel={(option) => option?.name}
                                  getOptionSelected={(option, value) =>
                                    option?.id === value?.id || value?.id === 0
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={item?.label}
                                      placeholder={item?.placeholder}
                                      error={
                                        !!errors?.areas?.[index]?.[item?.name]
                                      }
                                      helperText={
                                        errors?.areas?.[index]?.[item?.name]
                                          ?.message
                                      }
                                      InputProps={{
                                        ...params.InputProps,
                                        ...(!isArea && {
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <UserIcon size={20} />
                                            </InputAdornment>
                                          ),
                                        }),
                                        style: {
                                          ...(isArea && {
                                            fontWeight:
                                              theme.typography.h6.fontWeight,
                                          }),
                                          backgroundColor:
                                            theme.palette.common.white,
                                        },
                                      }}
                                    />
                                  )}
                                  disabled={isArea || isCompleted || isInactive}
                                />
                              }
                              control={control}
                              mode="onChange"
                              name={`areas[${index}].${item?.name}`}
                              onChange={([_, data]) => data}
                            />
                          </Grid>
                        )
                      },
                    )}
                  </Grid>
                ))}
              </Box>
            </Grid>
          )}
          <Grid item xs={12} className={classes.gridItemContainer}>
            <Box
              className={classes.titleContainer}
              color={
                !!errors.purpose
                  ? theme.palette.error.main
                  : theme.palette.primary.main
              }
            >
              <Box>
                <TrendingUpIcon size={20} />
              </Box>
              <Typography variant="h6">Finalidade do projeto</Typography>
            </Box>
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.purpose}
                  helperText={errors.purpose?.message}
                  multiline
                  minRows={4}
                  maxRows={4}
                  disabled={isCompleted || isInactive}
                  fullWidth
                />
              }
              control={control}
              mode="onChange"
              name="purpose"
            />
          </Grid>
        </Grid>
      </Box>
      {isEdit && (
        <AlertDialog
          open={openAlertDialog}
          onClose={handleCloseAlertDialog}
          variant="standard"
          severity="info"
          title="Alteração do prazo do projeto de governança"
          content="Alterar o prazo do projeto pode impactar os prazos das atividades e dos planos de ação. Se algum deles tiver um prazo menor que o do projeto, sua data final será ajustada para coincidir com a do projeto."
        />
      )}
    </BaseDialog>
  )
}

ProjectSettingsModal.propTypes = {
  governanceProject: PropTypes.object,
  isEdit: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  projectTemplates: PropTypes.arrayOf(PropTypes.object),
  projectResponsible: PropTypes.arrayOf(PropTypes.object),
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

export default ProjectSettingsModal
