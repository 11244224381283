import { colors, makeStyles } from '@material-ui/core'
import constants from 'constants/index'

const styles = ({ projectStatus, overdueActivity = false }) => {
  const {
    CHOOSE_STATUS_INDEX_CARD_COLOR,
    CHOOSE_STATUS_INDEX_CARD_TEXT_COLOR,
  } = constants.governanceProject

  return makeStyles((theme) => ({
    cardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gridGap: theme.spacing(2),
      paddingInline: theme.spacing(2),
      minHeight: 60,
    },
    cardActionContainer: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gridGap: theme.spacing(2),
    },
    dateCard: {
      backgroundColor:
        CHOOSE_STATUS_INDEX_CARD_COLOR[projectStatus] ||
        theme.palette.primary.main,
      borderRadius: 4,
      border: `1px solid ${
        CHOOSE_STATUS_INDEX_CARD_TEXT_COLOR[projectStatus] || 'black'
      }`,
      color: `${CHOOSE_STATUS_INDEX_CARD_TEXT_COLOR[projectStatus] || 'white'}`,
      display: 'flex',
      alignItems: 'center',
      gridGap: theme.spacing(2),
      padding: theme.spacing(2),
      height: '100%',
    },
    activityCard: {
      backgroundColor: overdueActivity ? colors.red[100] : 'white',
      borderRadius: 4,
      border: `1px solid ${
        overdueActivity
          ? theme.palette.error.main
          : theme.palette.secondary.main
      }`,
      color: overdueActivity
        ? theme.palette.error.main
        : theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gridGap: theme.spacing(2),
      padding: theme.spacing(2),
    },
  }))
}

export default styles
