import React, { useRef } from 'react'
import Chart from 'react-apexcharts'
import { Box, lighten } from '@material-ui/core'
import PropTypes from 'prop-types'
import theme from 'theme'

const RadialGradientChart = ({
  value = 0,
  height = 370,
  width = '100%',
  hollowSize = '65%',
  startAngle = 0,
  endAngle = 360,
  fontSize = '44px',
  fontFamily = 'Raleway, Roboto, Arial, sans-serif',
  primaryColor = theme.palette.primary.main,
  secondaryColor = lighten(theme.palette.azure, 0.5),
  showValue = true,
  valueFormat = (val) => `${val}%`,
  trackBackground = 'transparent',
  legendText = '',
  scale = 1.0,
  offsetY = 10,
  containerProps,
}) => {
  const ref = useRef(null)

  const options = {
    chart: {
      type: 'radialBar',
      toolbar: { show: false },
    },
    plotOptions: {
      radialBar: {
        startAngle,
        endAngle,
        hollow: {
          size: hollowSize,
        },
        track: {
          background: trackBackground,
          strokeWidth: '100%',
        },
        dataLabels: {
          name: { show: false },
          value: {
            show: showValue,
            fontSize,
            fontWeight: 'bold',
            fontFamily,
            color: primaryColor,
            formatter: valueFormat,
            offsetY: offsetY,
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        gradientFrom: secondaryColor,
        gradientToColors: [primaryColor],
        stops: [0, 100],
      },
    },
    labels: [legendText],
  }

  return (
    <Box
      width="100%"
      ref={ref}
      style={{ transform: `scale(${scale})`, transformOrigin: 'center' }}
      {...containerProps}
    >
      <Chart
        ref={ref}
        options={options}
        series={[value]}
        type="radialBar"
        height={height}
        width={width}
      />
    </Box>
  )
}

RadialGradientChart.propTypes = {
  endAngle: PropTypes.number,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.number,
  hollowSize: PropTypes.string,
  legendText: PropTypes.string,
  offsetY: PropTypes.number,
  primaryColor: PropTypes.string,
  scale: PropTypes.number,
  secondaryColor: PropTypes.string,
  showValue: PropTypes.bool,
  startAngle: PropTypes.number,
  trackBackground: PropTypes.string,
  value: PropTypes.number,
  valueFormat: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default RadialGradientChart

// Exemplo de uso:
// <RadialGradientChart
//   value={75}
//   height={400}
//   primaryColor="#FF0000"
//   secondaryColor="#FFE0E0"
//   fontSize="32px"
//   valueFormat={(val) => `${val}k`}
// />

/* Exemplo de uso com legendas:
<RadialGradientChart 
  value={75}
  showLegend={true}
  legendText="Progresso Total"
  legendPosition="bottom"
  legendFontSize="16px"
  legendColor="#666"
  legendFormatter={(val) => `${val} - Meta Anual`}
  legendHorizontalAlign="center"
  legendMargin={20}
/>
*/
