import React from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import {
  CheckCircle as CheckCircleIcon,
  Watch as WatchIcon,
  X as XIcon,
} from 'react-feather'
import {
  Box,
  Card,
  CardActionArea,
  colors,
  Typography,
} from '@material-ui/core'

import helpers from 'helpers'

import theme from 'theme'
import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'

const ActivityBoard = ({ governanceProjectId, activity }) => {
  const {
    CHOOSE_STATUS_BOARD_ICON,
    CHOOSE_STATUS_BOARD_COLOR,
    OVERDUE_STATUS_ID,
    IN_PROGRESS_STATUS_ID,
  } = constants.governanceProject

  const color = CHOOSE_STATUS_BOARD_COLOR[activity?.status] || colors.grey[500]

  const classes = useStyles(color)()
  const history = useHistory()

  const Icon = CHOOSE_STATUS_BOARD_ICON[activity?.status] || XIcon

  const isOverdue = activity?.status === OVERDUE_STATUS_ID
  const isInProgress = activity?.status === IN_PROGRESS_STATUS_ID

  const handleManageActivity = () =>
    history.push(
      reverse(routes.governanceProjects.activity.manage, {
        governanceProjectId,
        activityId: activity?.id,
      }),
    )

  return (
    <Card className={classes.cardItemBoard}>
      <CardActionArea onClick={handleManageActivity}>
        <Box className={classes.cardActionArea}>
          <Box
            display="flex"
            gridGap={theme.spacing(2)}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" color="primary">
              {activity?.theme || 'Não definido'}
            </Typography>
            <Box className={classes.containerIcon}>
              <Icon
                size={20}
                strokeWidth={2.5}
                className={classes.categoryIcon}
              />
            </Box>
          </Box>
          <Box display="flex" alignContent="center" gridGap={theme.spacing(2)}>
            <Box
              className={classes.paralelogramoContainer}
              bgcolor={isInProgress ? color : 'transparent'}
            >
              <Box
                display="flex"
                gridGap={theme.spacing(1)}
                alignItems="center"
                className={classes.paralelogramo}
                color={
                  isInProgress
                    ? theme.palette.common.white
                    : theme.palette.secondary.main
                }
              >
                <CheckCircleIcon size={14} />
                <Typography variant="caption" className={classes.typography}>
                  {`${activity?.evidences?.completed}/${activity?.evidences?.total}`}
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.paralelogramoContainer}
              bgcolor={isOverdue || isInProgress ? color : 'transparent'}
            >
              <Box
                className={classes.paralelogramo}
                color={
                  isOverdue || isInProgress
                    ? theme.palette.common.white
                    : theme.palette.secondary.main
                }
              >
                <WatchIcon size={14} />
                <Typography variant="caption" className={classes.typography}>
                  {helpers.formatters.date(activity?.deadlineDate) || '-'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}

ActivityBoard.propTypes = {
  activity: PropTypes.object,
}

export default ActivityBoard
