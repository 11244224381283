import methods from './methods'

const isObject = (object) => object !== null && typeof object === 'object'
const isFile = (object) =>
  object instanceof File || object instanceof Blob || object instanceof FileList

function changeKeysFactory(changeCase) {
  return function changeKeys(object, options) {
    if (!isObject(object) || isFile(object)) {
      if (object instanceof FileList) {
        const files = []
        for (const file of object) {
          files.push(file)
        }

        return files
      }
      return object
    }

    if (Array.isArray(object)) {
      return object.map((item) => changeKeys(item, options))
    }

    const result = Object.create(Object.getPrototypeOf(object))

    Object.keys(object).forEach((key) => {
      const value = object[key]
      const changedKey = changeCase(key, options)
      const changedValue = changeKeys(value, options)
      result[changedKey] = changedValue
    })

    return result
  }
}

const camelCase = changeKeysFactory(methods.camelCase)
const capitalCase = changeKeysFactory(methods.capitalCase)
const constantCase = changeKeysFactory(methods.constantCase)
const dotCase = changeKeysFactory(methods.dotCase)
const trainCase = changeKeysFactory(methods.trainCase)
const noCase = changeKeysFactory(methods.noCase)
const kebabCase = changeKeysFactory(methods.kebabCase)
const pascalCase = changeKeysFactory(methods.pascalCase)
const pathCase = changeKeysFactory(methods.pathCase)
const sentenceCase = changeKeysFactory(methods.sentenceCase)
const snakeCase = changeKeysFactory(methods.snakeCase)

export default {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  trainCase,
  noCase,
  kebabCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
}
