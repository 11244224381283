import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  evidenceCard: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    gridGap: theme.spacing(1),
    borderRadius: 4,
  },
  lineBar: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.subscription.primary.light,
    border: `1px solid ${theme.palette.custom.border}`,
  },
}))

export default styles
