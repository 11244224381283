import React, { isValidElement } from 'react'
import { X as XIcon } from 'react-feather'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import { isEmpty } from 'lodash'

import styles from './styles'

import constants from 'constants/index'
import theme from 'theme'

const AlertDialog = ({
  content,
  dialogActions,
  dialogActionsProps,
  dialogContentProps,
  Icon,
  onClose = () => {},
  open = false,
  severity = 'success',
  variant = 'standard',
  hiddenCloseButton = false,
  title = '',
  ...rest
}) => {
  const useStyles = styles(variant, severity)
  const classes = useStyles()
  const AlertIcon = Icon || constants.alert.CHOOSE_DIALOG_ICON[severity]

  const showCloseIcon = !dialogActions && !hiddenCloseButton

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...rest}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle align="center" className={classes.dialogTitle}>
        {showCloseIcon && (
          <Box display="flex" justifyContent="end">
            <IconButton onClick={onClose}>
              <XIcon size={20} className={classes.dialogContentText} />
            </IconButton>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gridGap={theme.spacing(3)}
          mt={showCloseIcon ? 0 : 4}
        >
          <AlertIcon size={82} />
          {!isEmpty(title) && <Typography variant="h4">{title}</Typography>}
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          className={classes.dialogContentText}
          {...dialogContentProps}
          component={Box}
        >
          {isValidElement(content) ? (
            content
          ) : (
            <Typography
              className={classes.dialogContentTypography}
              variant="h5"
              align="center"
            >
              {content}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      {dialogActions && (
        <>
          <Divider className={classes.divider} />
          <DialogActions
            className={classes.dialogActions}
            {...dialogActionsProps}
          >
            {dialogActions}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

AlertDialog.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  dialogActions: PropTypes.node,
  dialogActionsProps: PropTypes.object,
  dialogContentProps: PropTypes.object,
  Icon: PropTypes.elementType,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  hiddenCloseButton: PropTypes.bool,
}

export default AlertDialog
