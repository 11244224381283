import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  outlinedSuccessButton: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      borderColor: theme.palette.success.main,
    },
  },
  containerSuccessButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
    },
  },
}))

export default styles
