import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ governanceProjectId = '', id = '' }) => {
  return await dponetAPI.get(
    `/governance_projects/${governanceProjectId}/governance_project_activities/${id}`,
  )
}

const answer = async ({ governanceProjectId, id, ...params }) => {
  return await dponetAPI.patch(
    `/governance_projects/${governanceProjectId}/governance_project_activities/${id}/answer`,
    {
      governanceProjectActivity: params,
    },
  )
}

const changeStatus = async ({ governanceProjectId, id, ...params }) => {
  return await dponetAPI.patch(
    `/governance_projects/${governanceProjectId}/governance_project_activities/${id}/change_status`,
    {
      governanceProjectActivity: params,
    },
  )
}

export default {
  get,
  answer,
  changeStatus,
}
